import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Modal,
  Image,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation, useNavigationState } from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { Beer } from '../../services/types';
import Button from '../ui/Button';
import Colors from '../../constants/Colors';
import BeerApi from '../../services/modules/beer/api';
import OrganizationApi from '../../services/modules/organization/api';
import BeerEditor from './BeerEditor';
import { createBeer } from '../../services/helpers';
import { baseURL } from '../../services/global';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';
import { FontAwesome } from '@expo/vector-icons';
import { useDispatch } from 'react-redux';
import { selectBeer } from '../../services/modules/beer/actions';

interface Props {
}

const PendingBeer: React.FC<Props> = ({
}) => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();

  const dispatch = useDispatch();

  const [beers, setBeers] = React.useState([]);
  const [beer, setBeer] = React.useState<Beer>(createBeer());
  const [loading, setLoading] = React.useState(true);
  const [count, setCount] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);

  const editBeer = (item: Beer) => {
    dispatch(selectBeer(item));
    navigation.navigate("Beer");
  }

  const loadBeers = () => {
    BeerApi.pending()
    .then((apiResponse: any) => {
      setBeers(apiResponse.data);
      setCount("(" + apiResponse.data.length + ")");
      setLoading(false);
    });
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const drawCard = (({ item }: { item: any }) => {
    return (
      <TouchableOpacity onPress={() => editBeer(item)}>
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
            {item.photo.length > 0 && <Image source={{ uri: baseURL + "/beer/photo/file/" + item.id + "/" + item.photo }} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
            {item.photo.length == 0 && <Image source={require('../../assets/images/can.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
            <View style={{ marginTop: 5 }}>
              <Text style={styles.cardHeader}>{item.title}</Text>
              <Text style={styles.cardSubheader}>{item.organizationTitle}</Text>
              <Text style={styles.cardSubheader}>{item.userCreated.name}</Text>
              <Text style={styles.cardSubheader}>{item.userCreated.email}</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>)
  })

  //initial load and when they navigate back from making changes
  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        loadBeers();
      }
    }, [isFocused])
  );

  return (<View>
    {beers.length > 0 && <View style={styles.panelContainer}>
        <View style={styles.sectionHeaderView}>
          <Text style={styles.sectionHeader}>BEER PENDING REVIEW {count}</Text>
          <TouchableOpacity onPress={toggleExpanded} style={{paddingRight: 5}}>
            {expanded && <FontAwesome name="arrow-circle-up" color="#CCCCCC" size={30}/>}
            {!expanded && <FontAwesome name="arrow-circle-down" color="#CCCCCC" size={30}/>}
          </TouchableOpacity>
        </View>     
        
        {loading &&
          <View style={{ marginTop: 40 }}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.loadingText}>Loading...</Text>
          </View>
        }

        {beers.length > 0 && !loading && expanded && <View>
          <FlatList
            keyExtractor={(item: any, index) => item.id.toString()}
            data={beers}
            renderItem={drawCard}
          />
        </View>}
      </View>}
  </View>
  )
};

const styles = StyleSheet.create({
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    margin: 10,
  },

  inputLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  selectBox: {
    padding: 5,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubheader: {
    fontSize: 18,
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardInfoContainer: {
  },

  cardDetail: {
    fontSize: 14,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderButton: {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  progressBar: {
    height: 20,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5
  },
  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  searchInputContainer: {
    backgroundColor: Colors.white,
  },

  panelContainer: {   
    backgroundColor: Colors.white, 
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "98%",  
    padding: "1%",
    marginBottom: "1%"
  },
});
export default PendingBeer