import axiosInstance from "../../interceptor";

const OrganizationApi = {
  beerList: (organizationId: number) => axiosInstance.get('organization/' + organizationId + '/beer/list'),
  list: (type: number, page: number) => axiosInstance.get('organization/list/' + type + '/' + page),
  listAll: (type: number) => axiosInstance.get('organization/selectlist/' + type),
  load: (organizationId: number) => axiosInstance.get('organization/' + organizationId),
  search: (keyword: string) => axiosInstance.get('organization/search/' + keyword),
  save: (id: number, title: string, address: string, city: string, province: string, postalCode: string, contactPhone: string, websiteURL: string, facebookURL: string, twitterURL: string, instagramURL: string) => axiosInstance.post('/organization', {id: id, title: title, address: address, city: city, province: province, postalCode: postalCode, contactPhone: contactPhone, websiteURL: websiteURL, facebookURL: facebookURL, twitterURL: twitterURL, instagramURL: instagramURL}),
  addBeer: (organizationId: number, beerId: number) => axiosInstance.post('/organization/beer/add', {id: 0, organizationId: organizationId, beerId: beerId, approved: true}),
  removeBeer: (id: number, beerId: number) => axiosInstance.post('/organization/beer/remove', {id: id, beerId: beerId}),
  listBeer: (id: number) => axiosInstance.get('organization/' +  id + '/beer/list'),

  uploadPhoto: (data: FormData) => axiosInstance.post('organization/photo', data),
  downloadPhoto: (id: number) => axiosInstance.get('organization/photo/' + id),

  
  removeOrganization: (id: number) => axiosInstance.get('organization/remove/' + id),

};

export default OrganizationApi;
