import { createBeer } from "../../helpers";
import { SELECT_BEER, BeerDispatchTypes, BeerState } from "./types";

export const initialState: BeerState = { id: 0, beer: createBeer() };

export const beerReducer = (
  state: BeerState = initialState,
  action: BeerDispatchTypes
) => {
  switch (action.type){
    case SELECT_BEER:
      return {
        ...state,
        id: action.payload?.id,
        beer: action.payload
      }
    default:
        return state;
  }
}