import axiosInstance from "../../interceptor";

const BeerApi = {
  merge: (beerId: number, mergeBeerId: number) => axiosInstance.get('beer/merge/' + beerId + '/' + mergeBeerId),
  list: (page: number) => axiosInstance.get('beer/list/' + page),
  pending: () => axiosInstance.get('beer/pending'),
  listStyles: () => axiosInstance.get('beer/styles/list'),
  listOthers: (typeId: number) => axiosInstance.get('beer/others/list/' + typeId),
  listAdjuncts: (typeId: number) => axiosInstance.get('beer/adjuncts/list/' + typeId),
  listIngredients: (typeId: number) => axiosInstance.get('beer/ingredients/list/' + typeId),
  getEditorLists: () => axiosInstance.get('beer/editor/lists'),
  load: (beerId: number) => axiosInstance.get('beer/' + beerId),
  search: (keyword: string) => axiosInstance.get('beer/search/' + keyword),
  searchExclude: (keyword: string, excludeId: number) => axiosInstance.get('beer/searchexclude/' + keyword + '/' + excludeId),
  startsWithSearch: (letter: string) => axiosInstance.get('beer/startswith/' + letter),
  update: (id: number, title: string, abv: number, ibu: number, styles: string, adjuncts: string, ingredients: string, others: string, core: boolean, seasonal: boolean, newRelease: boolean, regularNameScore: number, punnyNameScore: number, wildNameScore: number, reviewed: boolean, releaseDate: Date | undefined) => axiosInstance.post('/beer', {id: id, title: title, abv: abv, ibu: ibu, styles: styles, ingredients: ingredients, adjuncts: adjuncts, others: others, core: core, seasonal: seasonal, newRelease: newRelease, regularNameScore: regularNameScore, punnyNameScore: punnyNameScore, wildNameScore: wildNameScore, reviewed: reviewed, releaseDate: releaseDate}),
  add: (title: string, abv: number, ibu: number, styles: string, adjuncts: string, ingredients: string, others: string, core: boolean, seasonal: boolean, newRelease: boolean, regularNameScore: number, punnyNameScore: number, wildNameScore: number, reviewed: boolean, releaseDate: Date | undefined) => axiosInstance.post('/beer', {id: 0, title: title, abv: abv, ibu: ibu, styles: styles, ingredients: ingredients, adjuncts: adjuncts, others: others, core: core, seasonal: seasonal, newRelease: newRelease, regularNameScore: regularNameScore, punnyNameScore: punnyNameScore, wildNameScore: wildNameScore, reviewed: reviewed, releaseDate: releaseDate}),
  uploadPhoto: (data: FormData) => axiosInstance.post('beer/photo', data),
  downloadPhoto: (id: number, photo: string) => axiosInstance.get('beer/photo/' + id + '/' + photo),
  downloadResizedPhoto: (id: number, photo: string) => axiosInstance.get('beer/photo/fileresized/' + id + '/' + photo),
  getRated: (id: string) => axiosInstance.get('account/' + id + '/rated'),
  getRecommendations: (id: string) => axiosInstance.get('account/recommendations/' + id),
  getLoplopsRecommendations: (id: string) => axiosInstance.get('account/loplopsrecommendations/' + id),
  saveOther: (id: number, title: string, typeID: number) => axiosInstance.post('/beer/other', {id: id, title: title, typeID: typeID}),
  saveIngredient: (id: number, title: string, typeID: number) => axiosInstance.post('/beer/ingredient', {id: id, title: title, typeID: typeID}),
  saveAdjunct: (id: number, title: string, typeID: number) => axiosInstance.post('/beer/adjunct', {id: id, title: title, typeID: typeID}),
  saveStyle: (id: number, title: string, description: string) => axiosInstance.post('/beer/style', {id: id, title: title, description: description}),


  removeOther: (id: number) => axiosInstance.get('beer/other/remove/' + id),
  removeIngredient: (id: number) => axiosInstance.get('beer/ingredient/remove/' + id),
  removeAdjunct: (id: number) => axiosInstance.get('beer/adjunct/remove/' + id),
  removeStyle: (id: number) => axiosInstance.get('beer/style/remove/' + id),


  
  removeBeer: (id: number) => axiosInstance.get('beer/remove/' + id),
};

export default BeerApi;