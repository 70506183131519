import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Dimensions,
  Modal,
  Alert,
  Share,
  Image,
  ActivityIndicator,
} from 'react-native';

import {useNavigation, useFocusEffect, useIsFocused} from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList, TextInput, TouchableOpacity } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../services/store';
import { Account, Beer, Organization } from '../services/types';
import { AxiosResponse } from 'axios';
import Button from '../components/ui/Button';
import Colors from '../constants/Colors';
import PlacesSearchBar from '../components/location/PlacesSearchBar';
import OrganizationApi from '../services/modules/organization/api';
import BeerEditor from '../components/beer/BeerEditor';
import { createBeer } from '../services/helpers';
import * as ImagePicker from 'expo-image-picker';
import { updateOrganizationTitle } from '../services/modules/auth/actions';

interface Props {
}

const OrganizationScreen: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const organizationState = useSelector((state: RootState) => state.organization);
  const authState = useSelector((state: RootState) => state.auth);

  const isFocused = useIsFocused()

  const [beers, setBeers] = React.useState([]);
  const [beer, setBeer] = React.useState<Beer>(createBeer());
  const [loading, setLoading] = React.useState(false);
  const [loadingBeer, setLoadingBeer] = React.useState(false);
  const [showAddBeerModal, setShowAddBeerModal] = React.useState(false);
  const [showPlaceFinder, setShowPlaceFinder] = React.useState(false);
  const [organizationTitle, setOrganizationTitle] = React.useState("");
  const [organizationAddress, setOrganizationAddress] = React.useState("");
  const [organizationCity, setOrganizationCity] = React.useState("");
  const [organizationProvince, setOrganizationProvince] = React.useState("");
  const [organizationPostalCode, setOrganizationPostalCode] = React.useState("");


  const [contactPhone, setContactPhone] = React.useState("");
  const [websiteURL, setWebsiteURL] = React.useState("");
  const [facebookURL, setFacebookURL] = React.useState("");
  const [twitterURL, setTwitterURL] = React.useState("");
  const [instagramURL, setInstagramURL] = React.useState("");
  

  const [selectedImage, setSelectedImage] = React.useState<string>("");

  const selectImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {
      setSelectedImage(result.uri);
    }
  }

  const close = (() => {
    navigation.navigate("Dashboard");
  });

  const addBeer = () => {
    setBeer(createBeer());
    setShowAddBeerModal(true);
  }

  const editBeer = (item: Beer) => {
    setBeer(item);
    setShowAddBeerModal(true);
  }

  const onCancel = () => {
    setShowAddBeerModal(false);
  }

  const onSaved = () => {
    setShowAddBeerModal(false);
    loadBeerList();
  }

  const saveOrganization = () => {
    if (organizationTitle.trim().length == 0) {
      Alert.alert("Missing Information", "Please enter a name for this organization.");
      return;      
    }

    if (organizationState.id) {
      OrganizationApi.save(organizationState.id, organizationTitle, organizationAddress, organizationCity, organizationProvince, organizationPostalCode, contactPhone, websiteURL, facebookURL, twitterURL, instagramURL)
      .then(() => {
        dispatch(updateOrganizationTitle(organizationTitle));
        
        if (selectedImage.length > 0) {
          uploadPhoto(organizationState.id as number);
        } else {
          //if (onSaved) {
            //onSaved();
          //}
          //setSaving(false);
          close();
        }
      })
    }
  }
  
  const uploadPhoto = async (id: number) => {  
    let filename = "organizationLogo.jpg";
    let type = 'image/jpeg';
    let formData = new FormData();
    formData.append('file', selectedImage);
    formData.append('filename', filename);
    formData.append('id', id.toString());
    formData.append('typeId', "2");

    OrganizationApi.uploadPhoto(formData)
    .then((apiResponse: any) => {
      if (onSaved) {
        onSaved();
      }
      close();
    })
  }

  const cancel = () => {
    if (onCancel) {
      onCancel();
    }
  }
  
  const removeBeer = (item: Beer) => {
    if (organizationState.id) {
      OrganizationApi.removeBeer(organizationState.id, item.id)
      .then(() => {
        init();
      })
    }
  }

  const removeOrganizatgion = () => {
    if (confirm("Are you sure you want to delete this organization?")) {
      if ( organizationState.id! > 0) {
        
        //setSaving(true);
        OrganizationApi.removeOrganization(organizationState.id!)
          .then((apiResponse: any) => {
           // setSaving(false);
            //init();
            close();
          })
          .catch((error) => {
            console.log(error)
            //setSaving(false);
          })
      }
    }
  }


  const placeSelected = (organization: Organization) => {
    setOrganizationAddress(organization.address);
    setOrganizationPostalCode(organization.postalCode);
    setOrganizationProvince(organization.province);
    setOrganizationCity(organization.city);
  }

  const init = () => {
    if (organizationState.id) {
      setLoading(true);

      OrganizationApi.load(organizationState.id)
      .then((apiResponse: any) => {
        setOrganizationTitle(apiResponse.data.title);
        setOrganizationAddress(apiResponse.data.address);
        setOrganizationCity(apiResponse.data.city);
        setOrganizationProvince(apiResponse.data.province);
        setOrganizationPostalCode(apiResponse.data.postalCode);
        setContactPhone(apiResponse.data.contactPhone);
        setWebsiteURL(apiResponse.data.websiteURL);
        setFacebookURL(apiResponse.data.facebookURL);
        setTwitterURL(apiResponse.data.twitterURL);
        setInstagramURL(apiResponse.data.instagramURL);
        setLoading(false);
        
        if (apiResponse.data.photo) {
          OrganizationApi.downloadPhoto(apiResponse.data.id)
          .then((apiResponse: any) => {
            setSelectedImage("data:image/jpeg;base64," + apiResponse.data);
          })
        } else {
          setSelectedImage("");
        }        
      });
      
      loadBeerList();
    }
  }

  const loadBeerList = () => {
    if (organizationState.id) {
      setLoadingBeer(true);
      OrganizationApi.listBeer(organizationState.id)
      .then((apiResponse: any) => {
        setBeers(apiResponse.data);
        setLoadingBeer(false);
      });
    }
  }

  useEffect(() => {
    if (!loading) {
      init();
    }
  }, [organizationState]);

  const drawCard = (({item} : {item: Beer}) => {
    return (
    <TouchableOpacity onPress={() => editBeer(item)}>
      <View style={styles.card}>
        <View style={styles.cardInfoContainer}>
          <Text style={styles.cardTitle}>{item.title}</Text>
        </View>
      </View>
    </TouchableOpacity>)
  })

  return (<View>  
            {showAddBeerModal && <Modal
              style={styles.modalView}
              animationType="slide"
              transparent={false}
              visible={showAddBeerModal}
              onRequestClose={() => {
                setShowAddBeerModal(!showAddBeerModal);
              }}
            >
              <BeerEditor organizationId={organizationState.id} beer={beer} onCancel={onCancel} onSaved={onSaved} />
            </Modal>} 
   
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.loadingText}>Loading...</Text>
              </View>
            }   

            {!showAddBeerModal && !loading && <View style={styles.mainContainer}>
              <Text style={styles.modalText}>Organization Information</Text>   
              <Text style={styles.inputLabel}>Name</Text>
              <View style={styles.inputGroup}>
                <TextInput
                  style={styles.input}
                  placeholder="Organization name"
                  placeholderTextColor="#c5c5c5"
                  value={organizationTitle}
                  onChangeText={(organizationTitle) => {
                    setOrganizationTitle(organizationTitle);
                  }}
                />
              </View>   
              <Text style={styles.inputLabel}>Contact Phone</Text>
              <View style={styles.inputGroup}>
                <TextInput
                  style={styles.input}
                  placeholder="Contact phone"
                  placeholderTextColor="#c5c5c5"
                  value={contactPhone}
                  onChangeText={(contactPhone) => {
                    setContactPhone(contactPhone);
                  }}
                />
              </View>  
              <View style={{flexDirection: 'row', marginVertical: 20}}>
                <View style={{flex:1}}>
                  <Text style={styles.inputLabel}>Website URL</Text>
                  <View style={styles.inputGroup}>
                    <TextInput
                      style={styles.input}
                      placeholder="Website URL"
                      placeholderTextColor="#c5c5c5"
                      value={websiteURL}
                      onChangeText={(websiteURL) => {
                        setWebsiteURL(websiteURL);
                      }}
                    />
                  </View>  
                </View>
                <View style={{flex:1}}>
                  <Text style={styles.inputLabel}>Facebook URL</Text>
                  <View style={styles.inputGroup}>
                    <TextInput
                      style={styles.input}
                      placeholder="Facebook URL"
                      placeholderTextColor="#c5c5c5"
                      value={facebookURL}
                      onChangeText={(facebookURL) => {
                        setFacebookURL(facebookURL);
                      }}
                    />
                  </View>  
                </View>
                <View style={{flex:1}}>  
                  <Text style={styles.inputLabel}>Twitter URL</Text>
                  <View style={styles.inputGroup}>
                    <TextInput
                      style={styles.input}
                      placeholder="Twitter URL"
                      placeholderTextColor="#c5c5c5"
                      value={twitterURL}
                      onChangeText={(twitterURL) => {
                        setTwitterURL(twitterURL);
                      }}
                    />
                  </View>  
                </View>
                <View style={{flex:1}}>  
                  <Text style={styles.inputLabel}>Instagram URL</Text>
                  <View style={styles.inputGroup}>
                    <TextInput
                      style={styles.input}
                      placeholder="Instagram URL"
                      placeholderTextColor="#c5c5c5"
                      value={instagramURL}
                      onChangeText={(instagramURL) => {
                        setInstagramURL(instagramURL);
                      }}
                    />
                  </View>  
                </View>
              </View>
              
              {showPlaceFinder && <PlacesSearchBar onPlaceSelected={placeSelected} />}
              <Text style={styles.inputLabel}>Street</Text>
              <View style={styles.inputGroup}>
                <TextInput
                  style={styles.input}
                  placeholder="Enter street address"
                  placeholderTextColor="#c5c5c5"
                  value={organizationAddress}
                  onChangeText={(organizationAddress) => {
                    setOrganizationAddress(organizationAddress);
                  }}
                />
              </View>  
              <Text style={styles.inputLabel}>City</Text>
              <View style={styles.inputGroup}>
                <TextInput
                  style={styles.input}
                  placeholder="Enter city"
                  placeholderTextColor="#c5c5c5"
                  value={organizationCity}
                  onChangeText={(organizationCity) => {
                    setOrganizationCity(organizationCity);
                  }}
                />
              </View>
              <Text style={styles.inputLabel}>Province</Text>
              <View style={styles.inputGroup}>
                <TextInput
                  style={styles.input}
                  placeholder="Enter province"
                  placeholderTextColor="#c5c5c5"
                  value={organizationProvince}
                  onChangeText={(organizationProvince) => {
                    setOrganizationProvince(organizationProvince);
                  }}
                />
              </View>
              <Text style={styles.inputLabel}>Postal Code</Text>
              <View style={styles.inputGroup}>
                <TextInput
                  style={styles.input}
                  placeholder="Enter postal code"
                  placeholderTextColor="#c5c5c5"
                  value={organizationPostalCode}
                  onChangeText={(organizationPostalCode) => {
                    setOrganizationPostalCode(organizationPostalCode);
                  }}
                />
              </View>
              
              <Text style={[styles.inputLabel, {marginTop:30}]}>Logo (at least 200px x 200px, equal width and height is optimal)</Text>
              <Button style={{marginHorizontal: 0, marginVertical: 15}} text="Select a Logo" onPress={selectImage} />
              {selectedImage.length > 0 && <Image source={{ uri: selectedImage.toString() }} resizeMode="contain" style={{ height: 200, margin: 10 }} />}
              
              <Text style={[styles.modalText, {marginBottom: 0, marginTop: 30}]}>Available Beer</Text>  
              <Button style={styles.entryButton} onPress={() => addBeer()}
                text="Add">
              </Button>
              
              {loadingBeer &&
                <View style={{marginTop: 40}}>
                  <ActivityIndicator size="large" color="#3F51B5" />
                  <Text style={styles.loadingText}>Loading...</Text>
                </View>
              }  

              <View>
                {beers.length == 0 && <Text style={styles.noLog}>No beer under this organization.</Text>} 
                {beers.length > 0 && <FlatList
                  keyExtractor={(item: any, index) => item.id.toString()}
                  data={beers}
                  renderItem={drawCard}
                  />}
              </View> 
              <Button style={styles.entryButton} onPress={() => saveOrganization()}
                text="Save">
              </Button>
              <Button style={styles.entryButton} onPress={() => close()}
                text="Cancel">
              </Button>

              {organizationState.id! > 0 && authState.role == "Admin" &&  <Button style={[styles.entryButton, { marginTop: 40 }]} onPress={() => removeOrganizatgion()}
                  text="Remove">
                </Button>}

            </View>}
          </View>
  )
};

/*

              <Button style={[styles.entryButton, {marginBottom: 10}]} onPress={() => setShowPlaceFinder(true)}
                text="Use Place Finder">
              </Button> */

const styles = StyleSheet.create({
  inputContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputView: {
    backgroundColor: "#fff",
    borderRadius: 30,
    height: 45,
    marginBottom: 20,
  },

  input: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.borderLightGrey,
    fontSize: 16,
    padding: 10,
    flex: 1,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  header: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputBox: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.primary,    
    fontSize: 16,
    padding: 10,
  },

  inputText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 5,
    marginRight: 10,
    fontWeight: 'bold',
  },

  searchingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  comingSoon: {
    fontSize: 20,
    color: "#fff"
  },

  button : {
    marginTop: 10
  },
  sectionContainer: {
    width: "100%"
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  sectionHeaderButton : {
    margin: 4,
  },
  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    marginTop: 10,
  }, 

  cardInfoContainer: {
  },

  cardTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubTitle: {
    fontSize: 14,
  },

  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.borderGrey,
    padding: "1%",
    borderWidth: 1,
    borderRadius: 10,
  },

  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },

  modalSubText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    margin: 20,
  },

  entryButton: {
    marginTop: "1%"
  },
  stat: {
    fontSize: 26,
    fontWeight: 'bold',
  },

  statTitle: {
    fontSize: 20,
  },

  statsContainer: {
    alignItems: 'center',
    marginHorizontal: 5,
    marginVertical: 20,
  },

  container: {
    flexDirection: 'row'
  },

  mainContainer: {
    backgroundColor: Colors.white, 
    padding: "1%",
    margin: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "98%"
  },

  sideContainer: {
    paddingTop: "1%",
    paddingRight: "1%",
    width: "29%",
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
});
export default OrganizationScreen