import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/core';
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { logout } from '../../services/modules/auth/actions';

const BackButton: React.FC = ({ 
}) => { 
  const navigation = useNavigation();

  return (<View style={styles.drawerButtonContainer}>   
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <FontAwesome name="chevron-left" color="#000000" size={30}/>
            </TouchableOpacity>
          </View>);
}

const styles = StyleSheet.create({
  drawerButtonContainer: {
    marginLeft: 10
  },
});

export default BackButton;