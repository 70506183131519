import { useNavigation } from '@react-navigation/core';
import React from 'react'

import {
  View,
  StyleSheet,
} from 'react-native';
import { useSelector } from 'react-redux';
import BeerEditor from '../components/beer/BeerEditor';

import BeerList from '../components/beer/BeerList';
import Colors from '../constants/Colors';
import { RootState } from '../services/store';
import { Beer } from '../services/types';

interface Props {
}

const BeerScreen: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();

  const beerState = useSelector((state: RootState) => state.beer);

  const onCancel = () => {
    navigation.goBack();
  }
  
  const onSaved = () => {
    navigation.goBack();
  }
  
  return (<View style={{flexDirection:'row'}}>
            <View style={styles.mainContainer}>        
              <BeerEditor beer={beerState.beer!} onCancel={onCancel} onSaved={onSaved} />
            </View>   
          </View>)
};

const styles = StyleSheet.create({ 
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  mainContainer: {
    backgroundColor: Colors.white, 
    padding: "1%",
    margin: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "98%"
  }
});

export default BeerScreen