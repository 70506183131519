import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Modal,
  Image,
} from 'react-native';

import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { Beer } from '../../services/types';
import Button from '../ui/Button';
import Colors from '../../constants/Colors';
import BeerApi from '../../services/modules/beer/api';
import OrganizationApi from '../../services/modules/organization/api';
import BeerEditor from './BeerEditor';
import { createBeer } from '../../services/helpers';
import { baseURL } from '../../services/global';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';

interface Props {
  organizationId?: number;
}

const BeerList: React.FC<Props> = ({
  organizationId
}) => {
  const isFocused = useIsFocused();
  const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;

  const [beers, setBeers] = React.useState([]);
  const [beer, setBeer] = React.useState<Beer>(createBeer());
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [moreResults, setMoreResults] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);


  const addBeer = () => {
    setBeer(createBeer());
    setShowModal(true);
  }

  const editBeer = (item: Beer) => {
    setBeer(item);
    setShowModal(true);
  }

  const onCancel = () => {
    setShowModal(false);
  }

  const onSaved = () => {
    reloadBeers();
    setShowModal(false);
  }

  const startsWith = (letter: string) => {  
    setLoading(true);
    BeerApi.startsWithSearch(letter)
    .then((apiResponse: any) => {
      setBeers(apiResponse.data);
      setLoading(false);
    });
  }

  const loadBeers = (reload: boolean, searchKeyword: string | undefined = undefined, clearList: boolean = false) => {
    // only show when initial load
    // prevents messy scroll when loading more
    if (page == 0)
      setLoading(true);

    if (organizationId) {
      OrganizationApi.beerList(organizationId)
        .then((apiResponse: any) => {
          // fix this to paginate?
          setBeers(apiResponse.data);
          setLoading(false);
        });
    } else {
      // if keyword is set, call search endpoint instead
      if (searchKeyword) {
        BeerApi.search(searchKeyword)
          .then((apiResponse: any) => {
            setBeers(apiResponse.data);
            setLoading(false);
          });
      }
      else {
        BeerApi.list(page)
          .then((apiResponse: any) => {
            if (reload) {
              setBeers(apiResponse.data);
            } else {
              if (clearList) {
                setBeers(apiResponse.data);
              }
              else {
                setBeers([].concat(beers, apiResponse.data));
              }
            }
            setLoading(false);
          });
      }

    }
  }

  const reloadBeers = () => {
    //after a beer is updated we have to reload
    //if the page is 0, setPage(0) does not trigger a reload
    if (page == 0) {
      loadBeers(true);
    } else {
      setPage(0);
    }
  }

  const drawCard = (({ item }: { item: Beer }) => {
    return (
      <TouchableOpacity onPress={() => editBeer(item)}>
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
            {item.photo.length > 0 && <Image source={{ uri: baseURL + "/beer/photo/file/" + item.id + "/" + item.photo }} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
            {item.photo.length == 0 && <Image source={require('../../assets/images/can.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
            <View style={{ marginTop: 5 }}>
              <Text style={styles.cardHeader}>{item.title}</Text>
              <Text style={styles.cardSubheader}>{item.organizationTitle}</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>)
  })

  const updateSearch = (text: string) => {
    setKeyword(text)
    if (text.length > 2) {
      loadBeers(false, text)
    }

    if (text.length == 0) {
      reloadBeers();
    }
  }


  const clearSearch = () => {
    setKeyword(undefined);

    loadBeers(false, undefined, true);
  }

  //pagination load
  useEffect(() => {
    loadBeers(false);
  }, [page]);

  //initial load and when they navigate back from making changes
  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        reloadBeers();
      }
    }, [isFocused])
  );

  return (<View>
    {showModal && <Modal
      style={styles.modalView}
      animationType="slide"
      transparent={true}
      visible={showModal}
      onRequestClose={() => {
        setShowModal(!showModal);
      }}
    > 
      <BeerEditor organizationId={organizationId} beer={beer} onCancel={onCancel} onSaved={onSaved} />
    </Modal>}
    {!showModal &&
      <View style={styles.sectionContainer}>
        <View style={styles.sectionHeaderView}>
          <Text style={styles.sectionHeader}>BEER</Text>
          <Button
            style={styles.sectionHeaderButton}
            text="Add"
            onPress={addBeer} />
        </View>

        {!organizationId && <SafeSearchBar
          platform={"default"}
          searchIcon={false}
          onChangeText={updateSearch}
          onCancel={clearSearch}
          value={keyword}
          placeholder="Search"
          inputContainerStyle={styles.searchInputContainer}
          inputStyle={styles.searchInput}
          containerStyle={styles.searchContainer}
        />}

        {!organizationId && <View style={{flexDirection: 'row', alignSelf: 'center'}}>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("A")}>A</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("B")}>B</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("C")}>C</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("D")}>D</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("E")}>E</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("F")}>F</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("G")}>G</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("H")}>H</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("I")}>I</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("J")}>J</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("K")}>K</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("L")}>L</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("M")}>M</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("N")}>N</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("O")}>O</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("P")}>P</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("Q")}>Q</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("R")}>R</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("S")}>S</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("T")}>T</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("U")}>U</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("V")}>V</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("W")}>W</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("X")}>X</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("Y")}>Y</Text>
          <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("Z")}>Z</Text>
        </View>}

        {loading &&
          <View style={{ marginTop: 40 }}>
            <ActivityIndicator size="large" color="#3F51B5" />
            <Text style={styles.loadingText}>Loading...</Text>
          </View>
        }

        {beers.length == 0 && !loading && <Text style={styles.noLog}>No beer in the database.</Text>}
        {beers.length > 0 && !loading && <View>
          <FlatList
            keyExtractor={(item: any, index) => item.id.toString()}
            data={beers}
            renderItem={drawCard}
          />
          {!keyword && !organizationId && <Button
            style={styles.entryButton}
            text="Load More"
            onPress={() => setPage(page + 1)} />}
        </View>}
      </View>}
  </View>
  )
};

const styles = StyleSheet.create({
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    margin: 10,
  },

  inputLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  selectBox: {
    padding: 5,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubheader: {
    fontSize: 18,
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardInfoContainer: {
  },

  cardDetail: {
    fontSize: 14,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderButton: {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  progressBar: {
    height: 20,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5
  },
  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  searchInputContainer: {
    backgroundColor: Colors.white,
  }
});
export default BeerList