import { AuthDispatchTypes, AUTHENTICATION_ERROR, AuthState, LOGIN_FAILURE, LOGIN_LOADING, LOGIN_SUCCESS, LOG_OUT, REFRESH_TOKEN, UPDATE_ACCOUNT, UPDATE_ORGTITLE } from "./types";

export const initialState: AuthState = { error: '', authenticated: false, id: '', firstName: '', lastName: '', role: '', email: '', profilePhoto: '', token: '', refreshToken: '', organizationId: 0, organizationTitle: "" };

export const authReducer = (
  state: AuthState = initialState,
  action: AuthDispatchTypes
) => {
  switch (action.type){
    case LOGIN_LOADING:
      return {
        ...state,
        loading: true,
        authenticated: false,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: true,
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        profilePhoto: action.payload.profilePhoto,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        role: action.payload.role,
        organizationId: action.payload.organizationId,
        organizationTitle: action.payload.organizationTitle,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: 'Invalid login credentials',
      }
    case UPDATE_ORGTITLE:
      return {
        ...state,
        organizationTitle: action.payload.organizationTitle
      }
    case REFRESH_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      }
    case LOG_OUT:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: '',
        id: '', 
        firstName: '', 
        lastName: '', 
        email: '', 
        profilePhoto: '', 
        token: '', 
        refreshToken: '',
        organizationId: 0
      }
    case UPDATE_ACCOUNT:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
      }
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        loading: false,
        authenticated: false,
        error: action.payload,
      }
    default:
        return state;
  }
}