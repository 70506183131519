import React, { useEffect, useRef } from 'react'

import {
  View,
  StyleSheet,
  ActivityIndicator,
  Image,
  Modal,
  createElement,
} from 'react-native';

import {useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import { TextInput } from 'react-native-gesture-handler';
import { Beer } from '../../services/types';
import Button from '../ui/Button';
import Colors from '../../constants/Colors';
import BeerApi from '../../services/modules/beer/api';
import * as ImagePicker from 'expo-image-picker';
import OrganizationApi from '../../services/modules/organization/api';
import { Picker } from '@react-native-picker/picker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/store';
import Select from 'react-select';
import Checkbox from 'expo-checkbox';
import InputSpinner from "react-native-input-spinner";
import BeerMerge from './BeerMerge';
import { baseURL } from '../../services/global';
import { formatDate } from '../../services/helpers';

interface Props {
  organizationId?: number;
  beer: Beer;
  onSaved?: () => void;
  onCancel?: () => void;
}

const BeerEditor: React.FC<Props> = ({ 
  organizationId,
  beer,
  onSaved,
  onCancel
}) => {   
  const isFocused = useIsFocused()
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const authState = useSelector((state: RootState) => state.auth);
  const frameRef =  useRef<any>();
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [showMergeModal, setShowMergeModal] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [ibu, setIBU] = React.useState("0");
  const [abv, setABV] = React.useState("0");
  const [coreSelected, setCoreSelected] = React.useState(true);
  const [seasonalSelected, setSeasonalSelected] = React.useState(true);
  const [newReleaseSelected, setNewReleaseSelected] = React.useState(true);
  const [reviewedSelected, setReviewedSelected] = React.useState(false);
  const [organizations, setOrganizations] = React.useState<any>([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = React.useState(0);
  const [selectedStyles, setSelectedStyles] = React.useState<any>([]);
  const [selectedAdjuncts1, setSelectedAdjuncts1] = React.useState<any>([]);
  const [selectedAdjuncts2, setSelectedAdjuncts2] = React.useState<any>([]);
  const [selectedAdjuncts3, setSelectedAdjuncts3] = React.useState<any>([]);
  const [selectedAdjuncts4, setSelectedAdjuncts4] = React.useState<any>([]);
  const [selectedIngredients1, setSelectedIngredients1] = React.useState<any>([]);
  const [selectedIngredients2, setSelectedIngredients2] = React.useState<any>([]);
  const [selectedIngredients3, setSelectedIngredients3] = React.useState<any>([]);
  const [selectedIngredients4, setSelectedIngredients4] = React.useState<any>([]);
  
  const [selectedOthers1, setSelectedOthers1] = React.useState<any>([]);
  const [selectedOthers2, setSelectedOthers2] = React.useState<any>([]);
  const [selectedOthers3, setSelectedOthers3] = React.useState<any>([]);
  
  const [releaseDate, setReleaseDate] = React.useState<Date | undefined>(undefined);

  const [selectedImage, setSelectedImage] = React.useState<string>("");
  const [selectedWallImage, setSelectedWallImage] = React.useState<string>("");
  const [adjuncts1, setAdjuncts1] = React.useState<any>([]);
  const [adjuncts2, setAdjuncts2] = React.useState<any>([]);
  const [adjuncts3, setAdjuncts3] = React.useState<any>([]);
  const [adjuncts4, setAdjuncts4] = React.useState<any>([]);
  const [beerStyles, setBeerStyles] = React.useState<any>([]); 
  const [ingredients1, setIngredients1] = React.useState<any>([]);
  const [ingredients2, setIngredients2] = React.useState<any>([]);
  const [ingredients3, setIngredients3] = React.useState<any>([]);
  const [ingredients4, setIngredients4] = React.useState<any>([]);

  const [others1, setOthers1] = React.useState<any>([]);
  const [others2, setOthers2] = React.useState<any>([]);
  const [others3, setOthers3] = React.useState<any>([]);
  
  const [regularNameScore, setRegularNameScore] = React.useState<any>(0);
  const [punnyNameScore, setPunnyNameScore] = React.useState<any>(0);
  const [wildNameScore, setWildNameScore] = React.useState<any>(0);
   
  const onMerged = () => {
    navigation.goBack()
  }

  useEffect(() => {
    const handler = (event:any) => {
      if ("data" in event) {
        try
        {
          const data = JSON.parse(event.data);

          if ("data" in data) {
            if (data.from == "cropper") {
              setEditing(false);
              setSelectedWallImage(data.data);  
            }
          }
        } catch {
        }
      }
    }
    
    window.addEventListener("message", handler)

    // clean up
    return () => window.removeEventListener("message", handler)
  }, [])

  const loadImage = () => {
    setEditing(true);

    setTimeout(() => {
      Image.getSize(selectedImage, (width, height) => {
        frameRef.current.contentWindow.postMessage(JSON.stringify({
          error: false,
          from: "portal",
          data: selectedImage,
          width: width,
          height: height
        }), '*');
      });
    }, 1000)
  }

  const selectImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      base64: true,
      quality: 1,
    });

    if (!result.cancelled) {
      setSelectedImage(result.uri);
    }
  }

  const downloadResizedImage = () => {    
    console.log(baseURL + "/beer/photo/fileresized/" + beer.id + "/" + beer.photo)
    fetch(baseURL + "/beer/fileresized/" + beer.id + "/" + beer.photo)    
    .then(res => res.blob())
    .then(blob => {
      try {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', beer.photo);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch(error) {
        console.log(error)
      }
    });
  };

  const saveBeer = async () => {
    if (title.trim().length == 0) {
      alert("Please enter a title.");
      return;
    }
    
    if (selectedStyles.length == 0) {
      alert("Please enter at least one type.");
      return;
    }

    if ((selectedImage.length > 0 && selectedWallImage.length == 0) || (selectedWallImage.length > 0 && selectedImage.length == 0)) {
      alert("Please create a wall image before saving.");
      return;
    }

    let selectedOthers: any[] = [];

    if (selectedOthers1.length > 0) {
      selectedOthers = [...selectedOthers, ...selectedOthers1];
    }

    if (selectedOthers2.length > 0) {
      selectedOthers = [...selectedOthers, ...selectedOthers2];
    }

    if (selectedOthers3.length > 0) {
      selectedOthers = [...selectedOthers, ...selectedOthers3];
    }

    
    let selectedAdjuncts: any[] = [];

    if (selectedAdjuncts1.length > 0) {
      selectedAdjuncts = [...selectedAdjuncts, ...selectedAdjuncts1];
    }

    if (selectedAdjuncts2.length > 0) {
      selectedAdjuncts = [...selectedAdjuncts, ...selectedAdjuncts2];
    }

    if (selectedAdjuncts3.length > 0) {
      selectedAdjuncts = [...selectedAdjuncts, ...selectedAdjuncts3];
    }

    if (selectedAdjuncts4.length > 0) {
      selectedAdjuncts = [...selectedAdjuncts, ...selectedAdjuncts4];
    }
    
    let selectedIngredients: any[] = [];

    if (selectedIngredients1.length > 0) {
      selectedIngredients = [...selectedIngredients, ...selectedIngredients1];
    }

    if (selectedIngredients2.length > 0) {
      selectedIngredients = [...selectedIngredients, ...selectedIngredients2];
    }

    if (selectedIngredients3.length > 0) {
      selectedIngredients = [...selectedIngredients, ...selectedIngredients3];
    }

    if (selectedIngredients4.length > 0) {
      selectedIngredients = [...selectedIngredients, ...selectedIngredients4];
    }

    let numericABV = 0;
    let numericIBU = 0;

    try {
      numericABV = parseFloat(abv);
    } catch { }

    try {
      numericIBU = parseFloat(ibu);
    } catch { }

    if (beer.id == 0) {   
      setSaving(true);
      BeerApi.add(title, numericABV, numericIBU, selectedStyles.map((value: any) => value.value).join("|"), selectedAdjuncts.map((value) => value.value).join("|"), selectedIngredients.map((value) => value.value).join("|"), selectedOthers.map((value) => value.value).join("|"), coreSelected, seasonalSelected, newReleaseSelected, regularNameScore, punnyNameScore, wildNameScore, reviewedSelected, releaseDate)
      .then(async (apiResponse: any) => {
        if (selectedOrganizationId > 0) {
          await OrganizationApi.addBeer(selectedOrganizationId, apiResponse.data.message);
        }

        if (selectedImage.length > 0) {
          uploadPhoto(apiResponse.data.message)
        } else {
          if (onSaved) {
            onSaved();
          }
          setSaving(false);
        }
      })
      .catch(() => {
        setSaving(false);
      })
    } else {
      setSaving(true);
      BeerApi.update(beer.id, title, numericABV, numericIBU, selectedStyles.map((value: any) => value.value).join("|"), selectedAdjuncts.map((value) => value.value).join("|"), selectedIngredients.map((value) => value.value).join("|"), selectedOthers.map((value) => value.value).join("|"), coreSelected, seasonalSelected, newReleaseSelected, regularNameScore, punnyNameScore, wildNameScore, reviewedSelected, releaseDate)
      .then(async (apiResponse: any) => {
        if (selectedOrganizationId > 0) {
          await OrganizationApi.addBeer(selectedOrganizationId, apiResponse.data.message);
        }
        
        if (selectedImage.length > 0) {
          uploadPhoto(apiResponse.data.message)
        } else {
          if (onSaved) {
            onSaved();
          }
          setSaving(false);
        }
      })
      .catch(() => {
        setSaving(false);
      })
    }
  }

  const uploadPhoto = async (id: number) => {  
    let formData = new FormData();
    formData.append('filename', "photo.jpg");
    formData.append('file', selectedWallImage);
    formData.append('id', id.toString());
    formData.append('typeId', "2");

    BeerApi.uploadPhoto(formData)
    .then((apiResponse: any) => {      
      let formData = new FormData();
      formData.append('filename', "originalphoto.jpg");
      formData.append('file', selectedImage);
      formData.append('id', id.toString());
      formData.append('typeId', "1");

      BeerApi.uploadPhoto(formData)
      .then((apiResponse: any) => {
        if (onSaved) {
          onSaved();
        }
      })
    });
  }

  const cancel = () => {
    if (onCancel) {
      onCancel();
    }
  }

  const init = () => {
    setLoading(true);

    if (organizationId) {
      setSelectedOrganizationId(organizationId);
    }
    
    if (beer.id == 0) {
      setSelectedStyles([]);
      setSelectedAdjuncts1([]);
      setSelectedAdjuncts2([]);
      setSelectedAdjuncts3([]);
      setSelectedAdjuncts4([]);
      setSelectedIngredients1([]);
      setSelectedIngredients2([]);
      setSelectedIngredients3([]);
      setSelectedIngredients4([]);
      setSelectedOthers1([]);
      setSelectedOthers2([]);
      setSelectedOthers3([]);
      setSelectedImage("");
      setSelectedWallImage("");
      setABV("0");
      setIBU("0");
      setCoreSelected(false);
      setNewReleaseSelected(false);
      setSeasonalSelected(false);
      setReviewedSelected(false)
      setTitle("");
      setReleaseDate(undefined)
    } else {
      if (beer.styles.length > 0) {
        var tempStyles: any[] = [];
        beer.styles.map((val: any, index) => {
          tempStyles.push({value: val.id, label: val.title});
        })        
        setSelectedStyles(tempStyles);
      }

      if (beer.adjuncts.length > 0) {
        var tempAdjuncts1: any[] = [];
        var tempAdjuncts2: any[] = [];
        var tempAdjuncts3: any[] = [];
        var tempAdjuncts4: any[] = [];
        beer.adjuncts.map((val: any, index) => {
          if (val.typeId == 1) {
            tempAdjuncts1.push({value: val.id, label: val.title});
          }
          
          if (val.typeId == 2) {
            tempAdjuncts2.push({value: val.id, label: val.title});
          }
          
          if (val.typeId == 3) {
            tempAdjuncts3.push({value: val.id, label: val.title});
          }
          
          if (val.typeId == 4) {
            tempAdjuncts4.push({value: val.id, label: val.title});
          }
        })        

        setSelectedAdjuncts1(tempAdjuncts1);
        setSelectedAdjuncts2(tempAdjuncts2);
        setSelectedAdjuncts3(tempAdjuncts3);
        setSelectedAdjuncts4(tempAdjuncts4);
      }

      if (beer.ingredients.length > 0) {
        var tempIngredients1: any[] = [];
        var tempIngredients2: any[] = [];
        var tempIngredients3: any[] = [];
        var tempIngredients4: any[] = [];
        beer.ingredients.map((val: any, index) => {
          if (val.typeId == 1) {
            tempIngredients1.push({value: val.id, label: val.title});
          }
          
          if (val.typeId == 2) {
            tempIngredients2.push({value: val.id, label: val.title});
          }
          
          if (val.typeId == 3) {
            tempIngredients3.push({value: val.id, label: val.title});
          }
          
          if (val.typeId == 4) {
            tempIngredients4.push({value: val.id, label: val.title});
          }
        })        
        
        setSelectedIngredients1(tempIngredients1);
        setSelectedIngredients2(tempIngredients2);
        setSelectedIngredients3(tempIngredients3);
        setSelectedIngredients4(tempIngredients4);
      }

      if (beer.others.length > 0) {
        var tempOthers1: any[] = [];
        var tempOthers2: any[] = [];
        var tempOthers3: any[] = [];
        
        beer.others.map((val: any, index) => {
          if (val.typeId == 1) {
            tempOthers1.push({value: val.id, label: val.title});
          }
          
          if (val.typeId == 2) {
            tempOthers2.push({value: val.id, label: val.title});
          }
          
          if (val.typeId == 3) {
            tempOthers3.push({value: val.id, label: val.title});
          }
          
        })        
        
        setSelectedOthers1(tempOthers1);
        setSelectedOthers2(tempOthers2);
        setSelectedOthers3(tempOthers3);
      }
      
      if (beer.photo && beer.photo.length > 0) {
        BeerApi.downloadPhoto(beer.id, beer.photo)
        .then((apiResponse: any) => {
          setSelectedWallImage("data:image/jpeg;base64," + apiResponse.data); 
          
          if (!beer.originalPhoto || beer.originalPhoto.length == 0) {
            setSelectedImage("data:image/jpeg;base64," + apiResponse.data);
          }
        })
      } else {
        setSelectedWallImage("");
      }

      if (beer.originalPhoto && beer.originalPhoto.length > 0) {
        BeerApi.downloadPhoto(beer.id, beer.originalPhoto)
        .then((apiResponse: any) => {
          setSelectedImage("data:image/jpeg;base64," + apiResponse.data); 
        })
      } else {
        if (!beer.photo || beer.photo.length == 0) {
          setSelectedImage("");
        }
      }

      if (beer.organizationId > 0) {
        setSelectedOrganizationId(beer.organizationId);
      }
      
      setABV(beer.abv.toString());
      setIBU(beer.ibu.toString());
      setSeasonalSelected(beer.seasonal);
      setCoreSelected(beer.core);
      setNewReleaseSelected(beer.newRelease);
      setTitle(beer.title);
      setReviewedSelected(beer.reviewed)
      setRegularNameScore(beer.regularNameScore);
      setPunnyNameScore(beer.punnyNameScore);
      setWildNameScore(beer.wildNameScore);

      if (beer.releaseDate != undefined) {
        setReleaseDate(new Date(beer.releaseDate))
      }
    }

    BeerApi.getEditorLists()
    .then((apiResponse: any) => {
      let tempStyles = apiResponse.data.styles;
      let selectStyles: any = [];

      tempStyles.map((item: any) => {
        selectStyles.push({value: item.id, label: item.title});
      })

      setBeerStyles(selectStyles);

      let tempAdjuncts = apiResponse.data.adjuncts;
      let selectAdjuncts1: any = [];
      let selectAdjuncts2: any = [];
      let selectAdjuncts3: any = [];
      let selectAdjuncts4: any = [];

      tempAdjuncts.map((item: any) => {
        if(item.typeId == 1) {
          selectAdjuncts1.push({value: item.id, label: item.title});
        }
        if(item.typeId == 2) {
          selectAdjuncts2.push({value: item.id, label: item.title});
        }
        if(item.typeId == 3) {
          selectAdjuncts3.push({value: item.id, label: item.title});
        }
        if(item.typeId == 4) {
          selectAdjuncts4.push({value: item.id, label: item.title});
        }

      })
      setAdjuncts1(selectAdjuncts1);
      setAdjuncts2(selectAdjuncts2);
      setAdjuncts3(selectAdjuncts3);
      setAdjuncts4(selectAdjuncts4);
      
      let tempIngredients = apiResponse.data.ingredients;
      let selectIngredients1 : any = [];
      let selectIngredients2 : any = [];
      let selectIngredients3 : any = [];
      let selectIngredients4 : any = [];
            
      tempIngredients.map((item: any) => {
        if(item.typeId == 1) {
          selectIngredients1.push({value: item.id, label: item.title});
        }
        if(item.typeId == 2) {
          selectIngredients2.push({value: item.id, label: item.title});
        }
        if(item.typeId == 3) {
          selectIngredients3.push({value: item.id, label: item.title});
        }
        if(item.typeId == 4) {
          selectIngredients4.push({value: item.id, label: item.title});
        }
        
      })
      setIngredients1(selectIngredients1);
      setIngredients2(selectIngredients2);
      setIngredients3(selectIngredients3);
      setIngredients4(selectIngredients4);



      let tempOthers = apiResponse.data.others;
      let selectOthers1 : any = [];
      let selectOthers2 : any = [];
      let selectOthers3 : any = [];
      
      
      tempOthers.map((item: any) => {
        if(item.typeId == 1) {
          selectOthers1.push({value: item.id, label: item.title});
        }
        if(item.typeId == 2) {
          selectOthers2.push({value: item.id, label: item.title});
        }
        if(item.typeId == 3) {
          selectOthers3.push({value: item.id, label: item.title});
        }
        
      })
      setOthers1(selectOthers1);
      setOthers2(selectOthers2);
      setOthers3(selectOthers3);
    });

    OrganizationApi.listAll(1)
    .then((apiResponse: any) => {
      if (apiResponse.data.length > 0) {
        setOrganizations(apiResponse.data);
      }

      setLoading(false)
    });
  }

  const saveSelections = (selected: number[]) => {
    setSelectedStyles(selected);
  }

  const saveAdjunctSelections = (typeId: number, selected: number[]) => {
    if (typeId == 1) {
      setSelectedAdjuncts1(selected);
    }
    
    if (typeId == 2) {
      setSelectedAdjuncts2(selected);
    }
    
    if (typeId == 3) {
      setSelectedAdjuncts3(selected);
    }
    
    if (typeId == 4) {
      setSelectedAdjuncts4(selected);
    }
  }

  const saveIngredientSelections = (typeId: number, selected: number[]) => {
    if (typeId == 1) {
      setSelectedIngredients1(selected);
    }
    
    if (typeId == 2) {
      setSelectedIngredients2(selected);
    }
    
    if (typeId == 3) {
      setSelectedIngredients3(selected);
    }
    
    if (typeId == 4) {
      setSelectedIngredients4(selected);
    }
  }

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        init();
      }
    }, [isFocused])
  );

  const merge = () => {
    setShowMergeModal(true)
  }

  const removeBeer = () => {
    if (confirm("Are you sure you want to delete this beer?")) {
      if (beer.id > 0) {
        setSaving(true);
        BeerApi.removeBeer(beer.id)
          .then((apiResponse: any) => {
            setSaving(false);

            if (onSaved) {
              onSaved();
            }
          })
          .catch((error) => {
            console.log(error)
            setSaving(false);
          })
      }
    }
  }

  const onChangePickUpDate = (selectedDate: any) => {
    setReleaseDate(new Date(selectedDate.target.value + "T12:00:00"))    
  };

  const DatePicker = ({value, onChange} : {value: Date | undefined, onChange: any}) => {
    if (value == undefined) {
      return createElement('input', {
        type: 'date',
        onChange: onChange,
      })
    } else {
      const [date, time] = formatDate(value).split(' ');

      return createElement('input', {
        type: 'date',
        value: date,
        onChange: onChange,
      })
    }
  }
  
  const customStyles = {
    //https://react-select.com/styles#styles
    
    menuPortal: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999,
    }),

    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: 20,
      borderColor: Colors.borderGrey
    }),

    multiValue: (provided: any, state: any) => ({
      ...provided,
      borderRadius: 15,
      fontSize: 18,
    })
  }

  return (<View>   
            {showMergeModal && <Modal
              style={styles.modalView}
              animationType="slide"
              transparent={true}
              visible={showMergeModal}
              onRequestClose={() => {
                setShowMergeModal(!showMergeModal);
              }}
            > 
              <BeerMerge beer={beer} onMerged={onMerged} />
              <Button style={[styles.entryButton, { marginTop: 10 }]} onPress={() => setShowMergeModal(false)}
                text="Cancel">
              </Button>
            </Modal>}
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.loadingText}>Loading...</Text>
              </View>
            }   
            {saving &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.loadingText}>Saving...</Text>
              </View>
            }
            {!loading && !saving && !showMergeModal &&        
              <View style={styles.sectionContainer}>
                <View style={{flexDirection: 'row'}}>
                  <View style={{width: "20%"}}></View>
                  <Text style={[styles.modalText, {width: "60%"}]}>Beer Editor</Text>    
                  {authState.role == "Admin" && <View style={{width: "20%"}}><Button text="Merge" onPress={() => {merge()}} /></View>}
                </View>
                <View style={{flexDirection: 'row'}}>
                  <View style={{width: "48%", margin: "1%"}}>
                    <Text style={styles.inputLabel}>Title</Text>
                    <View style={styles.inputGroup}>
                      <TextInput
                        style={styles.input}
                        placeholder="Enter beer title"
                        placeholderTextColor="#003f5c"
                        value={title}
                        onChangeText={(title) => {
                          setTitle(title);
                        }}
                      />
                    </View>
                  </View>  
                  <View style={{width: "48%", margin: "1%"}}>
                    <Text style={styles.inputLabel}>Brewery</Text>
                    <View style={styles.inputGroup}>
                      <Picker
                        style={styles.input}
                        enabled={authState.role != "Brewery"}
                        selectedValue={selectedOrganizationId}
                        onValueChange={(itemValue, itemIndex) => setSelectedOrganizationId(itemValue)}>
                          <Picker.Item key={0} label="Please Select" value={0} />

                          {
                            organizations.map( (v: any) => {
                              return <Picker.Item key={v.id} label={v.title} value={v.id} />
                            })
                          }
                      </Picker>
                    </View>
                  </View>  
                </View>
                <View style={{flexDirection: 'row'}}>
                  <View style={{width: "31%", margin: "1%"}}>
                    <Text style={styles.inputLabel}>ABV</Text>
                    <View style={styles.inputGroup}>
                      <TextInput
                        style={styles.input}
                        placeholder="Enter ABV"
                        placeholderTextColor="#003f5c"
                        value={abv.toString()}
                        keyboardType="decimal-pad"
                        onChangeText={(abv) => {
                          setABV(abv)
                        }}
                      />
                    </View>    
                  </View>  
                  <View style={{width: "31%", margin: "1%"}}>
                    <Text style={styles.inputLabel}>IBU</Text>
                    <View style={styles.inputGroup}>
                      <TextInput
                        style={styles.input}
                        placeholder="Enter IBU"
                        placeholderTextColor="#003f5c"
                        value={ibu.toString()}
                        keyboardType="decimal-pad"
                        onChangeText={(ibu) => {
                          setIBU(ibu)
                        }}
                      />
                    </View> 
                  </View>
                  <View style={{width: "31%", margin: "1%"}}>
                    <Text style={styles.inputLabel}>Type</Text>
                    <View style={styles.inputGroup}>
                      
                      <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="styles"
                        options={beerStyles}
                        defaultValue={selectedStyles}
                        onChange={setSelectedStyles}
                        closeMenuOnSelect={false}/>
                      
                      
                    </View> 
                  </View>
                </View>  
                <Text style={styles.inputLabel}>Adjuncts</Text>
                <View style={{flexDirection: 'row'}}>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Flavour</Text>
                    <View style={styles.inputGroup}>
                     
                       
                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="adjuncts1"
                        options={adjuncts1}
                        defaultValue={selectedAdjuncts1}
                        onChange={setSelectedAdjuncts1}
                        closeMenuOnSelect={false}/>
                      
                    

                    </View> 
                  </View>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Starch</Text>
                    <View style={styles.inputGroup}>
                     

                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="adjuncts2"
                        options={adjuncts2}
                        defaultValue={selectedAdjuncts2}
                        onChange={setSelectedAdjuncts2}
                        closeMenuOnSelect={false}/>
                      
                    

                    </View> 
                  </View>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Sugar</Text>
                    <View style={styles.inputGroup}>
                   

                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="adjuncts3"
                        options={adjuncts3}
                        defaultValue={selectedAdjuncts3}
                        onChange={setSelectedAdjuncts3}
                        closeMenuOnSelect={false}/>
                      
                    

                    </View> 
                  </View>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Spices</Text>
                    <View style={styles.inputGroup}>
                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="adjuncts4"
                        options={adjuncts4}
                        defaultValue={selectedAdjuncts4}
                        onChange={setSelectedAdjuncts4}
                        closeMenuOnSelect={false}/>
                      
                    
                    </View> 
                  </View>
                </View>
                <Text style={styles.inputLabel}>Ingredients</Text>
                <View style={{flexDirection: 'row'}}>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Hops</Text>
                    <View style={styles.inputGroup}>
                    
                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="ingredients1"
                        options={ingredients1}
                        defaultValue={selectedIngredients1}
                        onChange={setSelectedIngredients1}
                        closeMenuOnSelect={false}/>
                      
                    

                  
                    </View> 
                  </View>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Yeast</Text>
                    <View style={styles.inputGroup}>
                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="ingredients2"
                        options={ingredients2}
                        defaultValue={selectedIngredients2}
                        onChange={setSelectedIngredients2}
                        closeMenuOnSelect={false}/>
                      
                    </View> 
                  </View>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Malt</Text>
                    <View style={styles.inputGroup}>
                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="ingredients3"
                        options={ingredients3}
                        defaultValue={selectedIngredients3}
                        onChange={setSelectedIngredients3}
                        closeMenuOnSelect={false}/>
                      
                    </View> 
                  </View>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Water Source</Text>
                    <View style={styles.inputGroup}>
                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="ingredients4"
                        options={ingredients4}
                        defaultValue={selectedIngredients4}
                        onChange={setSelectedIngredients4}
                        closeMenuOnSelect={false}/>
                      
                    </View> 
                  </View>
                </View>












                <Text style={styles.inputLabel}>Styles</Text>
                <View style={{flexDirection: 'row'}}>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Country</Text>
                    <View style={styles.inputGroup}>
                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="others1"
                        options={others1}
                        defaultValue={selectedOthers1}
                        onChange={setSelectedOthers1}
                        closeMenuOnSelect={false}/>
                    </View> 
                  </View>
               
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Colour</Text>
                    <View style={styles.inputGroup}>
                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="others1"
                        options={others2}
                        defaultValue={selectedOthers2}
                        onChange={setSelectedOthers2}
                        closeMenuOnSelect={false}/>
                    </View> 
                  </View>


                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Other</Text>
                    <View style={styles.inputGroup}>
                    <Select
                        isMulti
                        menuPortalTarget={document.body} 
                        styles={customStyles}
                        name="others1"
                        options={others3}
                        defaultValue={selectedOthers3}
                        onChange={setSelectedOthers3}
                        closeMenuOnSelect={false}/>
                    </View> 
                  </View>
                </View>
                {authState.role == "Admin" && <Text style={styles.inputLabel}>Name Score</Text>}
                {authState.role == "Admin" && <View style={{flexDirection: 'row'}}>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Regular Name Score</Text>
                    <View style={styles.inputGroup}>
                    <InputSpinner
                        value={regularNameScore}
                        onChange={value => setRegularNameScore(value)}
                      />
                    </View> 
                  </View>               
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Punny Name Score</Text>
                    <View style={styles.inputGroup}>
                    <InputSpinner
                        value={punnyNameScore}
                        onChange={value => setPunnyNameScore(value)}
                      />
                    </View> 
                  </View>
                  <View style={{width: "23%", marginHorizontal: "1%"}}>
                    <Text style={styles.inputSubLabel}>Wild Name Score</Text>
                    <View style={styles.inputGroup}>
                    <InputSpinner
                        value={wildNameScore}
                        onChange={value => setWildNameScore(value)}
                      />
                    </View> 
                  </View>
                </View>}
                {authState.role == "Admin" && <Text style={styles.inputLabel}>Administration</Text>}
                {authState.role == "Admin" && <View style={styles.checkboxContainer}>
                  <Checkbox
                    value={reviewedSelected}
                    onValueChange={setReviewedSelected}
                    style={styles.checkbox}
                  />
                  <Text style={styles.label}>Reviewed</Text>
                </View>}
                <Text style={styles.inputLabel}>Release Information</Text>
                <View style={styles.checkboxContainer}>
                  <Checkbox
                    value={newReleaseSelected}
                    onValueChange={setNewReleaseSelected}
                    style={styles.checkbox}
                  />
                  <Text style={styles.label}>New release, show as new until</Text>
                  <DatePicker
                    value={releaseDate}
                    onChange={onChangePickUpDate}
                  />
                </View>
                <Text style={styles.inputLabel}>Availability Information</Text>
                <View style={styles.checkboxContainer}>
                  <Checkbox
                    value={coreSelected}
                    onValueChange={setCoreSelected}
                    style={styles.checkbox}
                  />
                  <Text style={styles.label}>Available at Loplops</Text>
                </View>
                <View style={styles.checkboxContainer}>
                  <Checkbox
                    value={seasonalSelected}
                    onValueChange={setSeasonalSelected}
                    style={styles.checkbox}
                  />
                  <Text style={styles.label}>Show in event menu</Text>
                </View>

                <Button style={{margin: 10}} text="Select a Beer Image" onPress={selectImage} />
                {selectedImage.length > 0 && <Text style={styles.inputLabel}>Original Photo</Text>}             
                {selectedImage.length > 0 && <Image source={{ uri: selectedImage.toString() }} resizeMode="contain" style={{ height: 200, margin: 10 }} />}
                {selectedWallImage.length > 0 && <Text style={styles.inputLabel}>Wall Photo</Text>}
                {selectedWallImage.length > 0 && <Image source={{ uri: selectedWallImage.toString() }} resizeMode="contain" style={{ height: 200, margin: 10 }} />}
                {selectedImage.length > 0 && selectedWallImage.length == 0 && <Button style={{marginBottom: 30}} onPress={loadImage} text="Create Wall Image" />}
                {selectedImage.length > 0 && selectedWallImage.length > 0 && <Button style={{marginBottom: 10}} onPress={loadImage} text="Re-crop Wall Image" />}
                {selectedImage.length > 0 && selectedWallImage.length > 0 && <div><a href={baseURL + "/beer/photo/fileresized/" + beer.id + "/" + beer.photo}>Download for Website</a></div>}
                {editing && <iframe 
                  ref={frameRef}
                  style={{width:280, height: 770}}
                  src={'https://loplops.digitalgrounds.ca/cropper?v=1'}
                />}
 
                <Button style={[styles.entryButton, {marginTop: 30}]} onPress={() => saveBeer()}
                  text="Save">
                </Button>
                <Button style={styles.entryButton} onPress={() => cancel()}
                  text="Cancel">
                </Button>


                {beer.id > 0 &&  <Button style={[styles.entryButton, { marginTop: 40 }]} onPress={() => removeBeer()}
                  text="Remove">
                </Button>}

              </View>}
          </View>
  )
};

/*
http://localhost:3000
  https://loplops.digitalgrounds.ca/cropper

<Button  onPress={() => { 

frameRef.current.contentWindow.postMessage(JSON.stringify({
      error: false,
      from: "portal",
      data: selectedImage
    }), '*');
    }} text="Edit Image" />
    
      <iframe 
                ref={frameRef}
                    style={{width:1000, height: 1000}}
                    src={' http://localhost:3000'}
                  />*/

const styles = StyleSheet.create({ 
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    marginVertical: 10,
  },

  inputLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 10,
  },

  inputSubLabel: {
    fontSize: 16,
    marginTop: 10,
  },

  inputGroup: {
   
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  selectBox: {
    padding: 5,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  }, 

  selectBoxLabel: {
    color: Colors.black,
    fontWeight: 'bold',
  },

  cardHeaderContainer: {
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardInfoContainer: {
  },

  cardDetail: {
    fontSize: 14,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderButton : {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  progressBar: {
    height: 20,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 10,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
  },
});
export default BeerEditor