const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export default {
  borderGrey: '#111',
  borderLightGrey: '#999',
  primary: '#ed1c25',
  white: '#fff',
  black: "#000",
};
