import { combineReducers } from "redux";
import { accountReducer } from "./modules/account/reducer";
import { appReducer } from "./modules/app/reducer";
import { authReducer } from './modules/auth/reducer'
import { beerReducer } from "./modules/beer/reducer";
import { organizationReducer } from "./modules/organization/reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  organization: organizationReducer,
  app: appReducer,
  beer: beerReducer,
  account: accountReducer
})