import { Beer } from "../../types";

export const SELECT_BEER = 'BEER/SELECT_BEER';

export interface BeerDispatchTypes {
  type: string;
  payload?: Beer;
}

export interface BeerState {
  id?: number;
  beer?: Beer;
}