export const ADD_ACCOUNT = 'ACCOUNT/ADD_ACCOUNT';
export const UPDATE_ACCOUNT = 'ACCOUNT/UPDATE_ACCOUNT';
export const SELECT_ACCOUNT = 'ACCOUNT/SELECT_ACCOUNT';

export interface AccountDispatchTypes {
  type: string;
  payload?: any;
}

export interface AccountState {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
}