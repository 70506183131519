import { Organization } from "../../types";

export const LOGIN_LOADING = 'AUTH/LOGIN_LOADING';
export const LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'AUTH/LOGIN_FAILED';
export const AUTHENTICATION_ERROR = 'AUTH/AUTHENTICATION_ERROR';
export const REFRESH_TOKEN = 'AUTH/REFRESH_TOKEN';
export const UPDATE_ACCOUNT = 'AUTH/UPDATE_ACCOUNT';
export const UPDATE_ORGTITLE = 'AUTH/UPDATE_ORGTITLE';
export const LOG_OUT = 'AUTH/LOG_OUT';

export interface AuthDispatchTypes {
  type: string;
  payload?: any;
}

export interface AuthState {
  authenticated: boolean;
  error: string;
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  token: string;
  refreshToken: string;
  profilePhoto: string;
  organizationId: number;
  organizationTitle: string;
}