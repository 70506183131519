import { FontAwesome } from '@expo/vector-icons';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../services/modules/auth/actions';
import { selectOrganization } from '../../services/modules/organization/actions';
import { RootState } from '../../services/store';
import Button from '../ui/Button';

const HamburgerMenu: React.FC = ({ 
}) => { 
  const dispatch = useDispatch();
  
  const authState = useSelector((state: RootState) => state.auth);

  const navigation = useNavigation();

  const openDrawer = () => {
    dispatch(logout());
    //navigation.navigate("Accounts");
  }

  const openOrganization = (entry: any) => {
    dispatch(selectOrganization(entry));
    navigation.navigate("Organization");
  };

  return (<View style={styles.drawerButtonContainer}> 
            {authState.role == "Brewery" && <Button style={{marginRight: 10}} onPress={() => { openOrganization({id: authState.organizationId})}} text="Edit Brewery" />}
            <Button onPress={openDrawer} text="Log Out" />
          </View>);
}

/*

            <TouchableOpacity onPress={openDrawer}>
              <FontAwesome name="bars" color="#000000" size={30}/>
            </TouchableOpacity>
            */
           
const styles = StyleSheet.create({
  drawerButtonContainer: {
    marginRight: 10,
    flexDirection: 'row'
  },
});

export default HamburgerMenu;