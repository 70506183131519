import { App } from "../../types";
import { LOAD_SETTINGS, AppDispatchTypes } from "./types";

export const initialState: App = { googleKey: '' };

export const appReducer = (
  state: App = initialState,
  action: AppDispatchTypes
) => {
  switch (action.type){
    case LOAD_SETTINGS:
      return {
        ...state,
        googleKey: action.payload
      }
    default:
        return state;
  }
}