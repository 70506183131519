import { Organization } from "../../types";

export const SELECT_BREWERY = 'SITE/SELECT_BREWERY';

export interface OrganizationDispatchTypes {
  type: string;
  payload?: Organization;
}

export interface OrganizationState {
  id?: number;
  title?: string;
}