import { AccountDispatchTypes, AccountState, ADD_ACCOUNT, SELECT_ACCOUNT, UPDATE_ACCOUNT } from "./types";

export const initialState: AccountState = { id: '', firstName: '', lastName: '', email: '', password: '' };

export const accountReducer = (
  state: AccountState = initialState,
  action: AccountDispatchTypes
) => {
  switch (action.type){
    case ADD_ACCOUNT:
      return {
        ...state,
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
      }
    case SELECT_ACCOUNT:
      return {
        ...state,
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
      }
    case UPDATE_ACCOUNT:
      return {
        ...state,
        id: action.payload.id,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
      }
    default:
        return state;
  }
}