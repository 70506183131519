import { SELECT_BREWERY, OrganizationDispatchTypes, OrganizationState } from "./types";

export const initialState: OrganizationState = { id: 0, title: '' };

export const organizationReducer = (
  state: OrganizationState = initialState,
  action: OrganizationDispatchTypes
) => {
  switch (action.type){
    case SELECT_BREWERY:
      return {
        ...state,
        id: action.payload?.id
      }
    default:
        return state;
  }
}