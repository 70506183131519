import React, { useEffect } from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Modal,
  Image,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList, TextInput } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';
import { Organization } from '../../services/types';
import { AxiosResponse } from 'axios';
import Button from '../ui/Button';
import Animated from 'react-native-reanimated';
import Colors from '../../constants/Colors';
import { whileStatement } from '@babel/types';
import { selectOrganization } from '../../services/modules/organization/actions';
import OrganizationApi from '../../services/modules/organization/api';
import { baseURL } from '../../services/global';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';

interface Props {
  typeId: number;
}

const OrganizationList: React.FC<Props> = ({
  typeId
}) => {
  const dispatch = useDispatch();

  const navigation = useNavigation();

  const isFocused = useIsFocused();

  const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;
  const [organizations, setOrganizations] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [newOrganizationTitle, setNewOrganizationTitle] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);

  const openOrganization = (entry: Organization) => {
    dispatch(selectOrganization(entry));
    navigation.navigate("Organization");
  };

  const addNewOrganization = () => {
    setNewOrganizationTitle("");
    setShowModal(true)
  };

  const saveNewOrganization = () => {
    setShowModal(false);
    setSaving(true);
    OrganizationApi.save(0, newOrganizationTitle, '', '', '', '', '', '', '', '', '')
      .then((apiResponse: any) => {
        let organization = { id: apiResponse.data.message, title: "", address: "", addressOptional: "", city: "", province: "", postalCode: "", contactName: "", contactEmail: "", contactPhone: "" }
        dispatch(selectOrganization(organization));
        setSaving(false);

        navigation.navigate("Organization");
      })
      .catch(() => {
        setSaving(false);
      })
  }

  const loadOrganizations = (reload: boolean, searchKeyword: string | undefined = undefined, clearList: boolean = false) => {
    // only show when initial load
    // prevents messy scroll when loading more
    if (page == 0)
      setLoading(true);


    // if keyword is set, call search endpoint instead
    if (searchKeyword != undefined) {
      OrganizationApi.search(searchKeyword)
        .then((apiResponse: any) => {

          setOrganizations(apiResponse.data);
          setLoading(false);
        });
    }
    else {

      OrganizationApi.list(typeId, page)
        .then((apiResponse: any) => {
          if (apiResponse.data.length > 0) {

            if (reload) {
              setOrganizations(apiResponse.data);
            } else {
              if (clearList) {
                setOrganizations(apiResponse.data);
              }
              else {
                setOrganizations([].concat(organizations, apiResponse.data));
              }



            }
          }
          setLoading(false);
        });
    }
  }

  const reloadOrganizations = () => {
    //after an org is updated we have to reload
    //if the page is 0, setPage(0) does not trigger a reload
    if (page == 0) {
      loadOrganizations(true);
    } else {
      setPage(0);
    }
  }

  const drawCard = (({ item }: { item: any }) => {
    return (
      <TouchableOpacity onPress={() => openOrganization(item)}>
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
            {item.photo != null && <Image source={{ uri: baseURL + "/organization/photo/file/" + item.id }} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
            {item.photo == null && <Image source={require('../../assets/images/no_logo.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
            <View style={{ marginTop: 5 }}>
              <Text style={styles.cardHeader}>{item.title}</Text>
              <Text style={styles.cardSubheader}>{item.city}</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>)
  })

  const updateSearch = (text: string) => {
    setKeyword(text)
    if (text.length > 2) {
      loadOrganizations(false, text)
    }

    if (text.length == 0) {
      reloadOrganizations();
    }
  }


  const clearSearch = () => {
    setKeyword(undefined);

    loadOrganizations(false, undefined, true);
  }

  //pagination load
  useEffect(() => {
    loadOrganizations(false);
  }, [page]);

  //initial load and when they navigate back from making changes
  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        reloadOrganizations();
      }
    }, [isFocused])
  );

  return (<View>
    {showModal && !saving && <Modal
      style={styles.modalView}
      animationType="slide"
      transparent={true}
      visible={showModal}
      onRequestClose={() => {
        setShowModal(!showModal);
      }}
    >
      <View>
        <Text style={styles.modalText}>New Organization</Text>
        <Text style={styles.inputLabel}>Organization Name</Text>
        <View style={styles.inputGroup}>
          <TextInput
            style={styles.input}
            placeholder="Organization name"
            placeholderTextColor="#003f5c"
            value={newOrganizationTitle}
            onChangeText={(newOrganizationTitle) => {
              setNewOrganizationTitle(newOrganizationTitle);
            }}
          />
        </View>
        <Button style={styles.entryButton} onPress={() => saveNewOrganization()}
          text="Save">
        </Button>
        <Button style={styles.entryButton} onPress={() => setShowModal(!showModal)}
          text="Cancel">
        </Button>
      </View>
    </Modal>}
    {!showModal &&
      <View style={styles.sectionContainer}>
        <View style={styles.sectionHeaderView}>
          <Text style={styles.sectionHeader}>BREWERIES</Text>
          <Button
            style={styles.sectionHeaderButton}
            text="Add"
            onPress={addNewOrganization} />
        </View>

        <SafeSearchBar
          platform={"default"}
          searchIcon={false}
          onChangeText={updateSearch}
          onClear={clearSearch}
          onCancel={clearSearch}
          value={keyword}
          placeholder="Search"
          inputContainerStyle={styles.searchInputContainer}
          inputStyle={styles.searchInput}
          containerStyle={styles.searchContainer}
        />

        <View>
          {loading &&
            <View style={{ marginTop: 40 }}>
              <ActivityIndicator size="large" color="#3F51B5" />
              <Text style={styles.loadingText}>Loading...</Text>
            </View>
          }
          {saving &&
            <View style={{ marginTop: 40 }}>
              <ActivityIndicator size="large" color="#3F51B5" />
              <Text style={styles.loadingText}>Saving...</Text>
            </View>
          }
          {organizations.length == 0 && !loading && <Text style={styles.noLog}>No organizations in the database.</Text>}
          {organizations.length > 0 && <View>
            <FlatList
              keyExtractor={(item: any, index) => item.id.toString() + index}
              data={organizations}
              renderItem={drawCard}
            />
            {!keyword && <Button
              style={styles.entryButton}
              text="Load More"
              onPress={() => setPage(page + 1)} />}
          </View>}
        </View>
      </View>}
  </View>
  )
};

const styles = StyleSheet.create({
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white,
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    margin: 10,
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
    fontWeight: 'bold'
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubheader: {
    fontSize: 18,
  },

  cardInfoContainer: {
  },

  cardDetail: {
    fontSize: 14,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderButton: {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  progressBar: {
    height: 20,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5
  },
  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  searchInputContainer: {
    backgroundColor: Colors.white,
  }
});
export default OrganizationList