import { useNavigation } from '@react-navigation/core';
import React from 'react'

import {
  View,
  StyleSheet,
} from 'react-native';
import IngredientList from '../components/beer/IngredientList';



import Button from '../components/ui/Button';
import Colors from '../constants/Colors';

interface Props {
}

const IngredientsScreen: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();

  return (<View style={{flexDirection:'row'}}>
            <View style={styles.mainContainer}>        
              <IngredientList />
            </View>   
          </View>)
};

const styles = StyleSheet.create({ 
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  mainContainer: {
    backgroundColor: Colors.white, 
    padding: "1%",
    margin: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "98%"
  }
});

export default IngredientsScreen