import axios from 'axios'
import React from 'react'
import {
  StyleSheet,
  View,
  TextInput,
  FlatList,
  Text
} from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useSelector } from 'react-redux'
import { placesBaseURL } from '../../services/global'
import { RootState } from '../../services/store'
import { PlacesPredictionType, Organization } from '../../services/types'

interface Props {
  onPlaceSelected: (place: Organization) => void
}

const PlacesSearchBar: React.FC<Props> = ({ 
  onPlaceSelected
}) => {   
  const appState = useSelector((state: RootState) => state.app)

  const [searchTerm, setSearchTerm] = React.useState("")
  const [predictions, setPredictions] = React.useState<PlacesPredictionType[]>([])
  const [showPredictions, setShowPredictions] = React.useState(false)

  const inputBottomRadius = showPredictions ?
    {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    }
    :
    {
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20
    };

  const runSearch = async () => {
    if (searchTerm.trim() === '')  {
      setShowPredictions(false);
      return;
    }
    
    const apiUrl = placesBaseURL + '/autocomplete/json?key=' + appState.googleKey + '&components=country:ca&input=' + searchTerm;

    try {
      const result = await axios.request({
        method: 'post',
        url: apiUrl
      })
      if (result) {
        const { data: { predictions } } = result
        setPredictions(predictions);
        setShowPredictions(true);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onPredictionTapped = async (placeId: string, description: string) => {
    const apiUrl = placesBaseURL + '/details/json?key=' + appState.googleKey + '&place_id=' + placeId;

    try {
      const result = await axios.request({
        method: 'post',
        url: apiUrl
      })

      if (result) {
        const { data: { result: { address_components: { addressComponents } } } } = result
        
        setShowPredictions(false);
        setSearchTerm(description);

        var postalCode = extractFromAdress(addressComponents, "postal_code");
        var address = extractFromAdress(addressComponents, "route");
        var city = extractFromAdress(addressComponents, "locality");
        var province = extractFromAdress(addressComponents, "administrative_area_level_1");

        const site: Organization = {
          id: 0,
          title: description,
          address: address,
          addressOptional: '',
          city: city,
          postalCode: postalCode,
          province: province,
          contactName: '',
          contactEmail: '',
          contactPhone: ''
        }
        
        onPlaceSelected(site);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const extractFromAdress = (components: any, type: any) => {
    for (var i=0; i<components.length; i++)
        for (var j=0; j<components[i].types.length; j++)
            if (components[i].types[j]==type) return components[i].long_name;

    return "";
  }

  return (
    <View style={styles.container}>
      <TextInput
        style={[styles.inputStyle, inputBottomRadius]}
        placeholder='Find an address (start typing the address)'
        placeholderTextColor="#003f5c"
        value={searchTerm}
        onChangeText={(term) => {
          setSearchTerm(term);
          runSearch();
        }}
        returnKeyType='search'
      />
      {showPredictions && <FlatList
        data={predictions}
        renderItem={({ item, index }) => {
          return (
            <TouchableOpacity
              style={styles.predictionRow}
              onPress={() => {onPredictionTapped(item.place_id, item.description)}}
            >
              <Text
                numberOfLines={1}
              >
                {item.description}
              </Text>
            </TouchableOpacity>
          )
        }}
        keyExtractor={(item) => item.place_id}
        style={styles.predictionsContainer}
      />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center'
  },
  inputStyle: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    backgroundColor: '#cfcfcf',
    borderRadius: 20,
    color: 'black',
    fontSize: 16,
    marginHorizontal: 20
  },
  predictionsContainer: {
    backgroundColor: '#cfcfcf',
    padding: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginHorizontal: 20
  },
  predictionRow: {
    paddingBottom: 15,
    marginBottom: 15,
    borderBottomColor: 'black',
    borderBottomWidth: 1,
  }
})
export default PlacesSearchBar