import React, { useEffect } from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Modal,
  Alert,
  Image,
} from 'react-native';

import {useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList, TextInput } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';
import { Account, Organization } from '../../services/types';
import { AxiosResponse } from 'axios';
import Button from '../ui/Button';
import Animated from 'react-native-reanimated';
import Colors from '../../constants/Colors';
import { whileStatement } from '@babel/types';
import AccountApi from '../../services/modules/account/api';
import {Picker} from '@react-native-picker/picker';
import OrganizationApi from '../../services/modules/organization/api';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';
import BeerApi from '../../services/modules/beer/api';
import { baseURL } from '../../services/global';

interface Props {
}

const AccountList: React.FC<Props> = ({ 
}) => {   
  const isFocused = useIsFocused()

  const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;

  const [accounts, setAccounts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [searching, setSearching] = React.useState(false);
  const [loadingUser, setLoadingUser] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [showUserModal, setShowUserModal] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [newAccount, setNewAccount] = React.useState(false);
  const [id, setId] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [role, setRole] = React.useState("Admin");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [organizations, setOrganizations] = React.useState([]);
  const [organizationId, setOrganizationId] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);
  const [beers, setBeers] = React.useState([]);
  const [recommendations, setRecommendations] = React.useState([]);
  const [loplopsRecommendations, setLoplopsRecommendations] = React.useState([]);
  const [personality, setPersonality] = React.useState("");
  const [userView, setUserView] = React.useState(1);

  const addAccount = () => {
    setNewAccount(true);
    setId("");
    setOrganizationId(0);
    setEmail("");
    setFirstName("");
    setLastName("");
    setShowModal(true)
  };

  const editAccount = (account: Account) => {
    if (role == "Admin" || role == "Brewery") {
      setNewAccount(false);
      setId(account.id);
      setOrganizationId(account.organizationId);
      setEmail(account.userName);
      setFirstName(account.firstName);
      setLastName(account.lastName);
      setShowModal(true)
    } else {
      setId(account.id);
      setFirstName(account.firstName);
      setLastName(account.lastName);
      setLoadingUser(true);
      setShowUserModal(true);
      
      loadPersonality(account.id);

      BeerApi.getRated(account.id)
      .then((apiResponse: any) => {
        setBeers(apiResponse.data);
      });

      BeerApi.getRecommendations(account.id)
      .then((apiResponse: any) => {
        setRecommendations(apiResponse.data);
        setLoadingUser(false);
      });

      BeerApi.getLoplopsRecommendations(account.id)
      .then((apiResponse: any) => {
        setLoplopsRecommendations(apiResponse.data);
      });
    }
  };

  const loadPersonality = (id: string) => {    
    AccountApi.loadSurvey(id)
    .then((apiResponse: AxiosResponse<any>) => {
      if (apiResponse.data != null) {
        let name = "";
        let survey = apiResponse.data;
        let score = survey.questionE + survey.questionF + survey.questionG;

        if (score >= 1 && score <= 3) {
          name = 'The Logic Lover';
        }
        else if (score >= 4 && score <= 6) {
          name = 'The Accidental Adventurer';
        }
        else if (score >= 7 && score <= 9) {
          name = 'The Easy Going Explorer';
        }
        else if (score >= 10 && score <= 12) {
          name = 'The Spontaneous Gambler';
        }
        else if (score >= 13 && score <= 15) {
          name = 'The Extreme Thrill Seeker';
        }

        setPersonality(name);
      } else {
        setPersonality("Personality survey not completed.");
      }
    })
  }

  const saveAccount = () => {
    if (email.trim().length == 0 || firstName.trim().length == 0 || lastName.trim().length == 0) {
      alert("Please fill in all fields.");
      return;
    }
    
    if (id == "") {      
      if (password.trim().length == 0) {
        alert("Please fill in all fields.");
        return;
      }
      
      if (password != confirmPassword) {
        alert("Passwords do not match.");
        return;
      }

      setShowModal(false);
      setSaving(true);
      AccountApi.add(email, firstName, lastName, organizationId, password)
      .then((apiResponse: any) => {
        setSaving(false);
        loadAccounts(role, 0);
      })
      .catch((error) => {
        console.log(error)
        alert(error.data.message);
        setSaving(false);
      })
    } else {
      setShowModal(false);
      setSaving(true);
      AccountApi.update(id, email, firstName, lastName, organizationId)
      .then((apiResponse: any) => {
        setSaving(false);
        loadAccounts(role, 0);
      })
      .catch((error) => {
        console.log(error)
        alert(error.data.message);
        setSaving(false);
      })
    }
  }

  const loadOrganizations = () => {  
    OrganizationApi.listAll(0)
    .then((apiResponse: any) => {
      let dropDownValues: any = [];

      dropDownValues.push({label: "No Organization", value: 0});

      apiResponse.data.map((item: any) => {
          dropDownValues.push({label: item.title, value: item.id});
      });

      setOrganizations(dropDownValues);
    });
  }

  const loadAccounts = (role: string, page: number) => {
    setLoading(true);

    AccountApi.list(role, page)
    .then((apiResponse: any) => {
      if (apiResponse.data.length > 0) {
        setRole(role);
        setAccounts(apiResponse.data);
      }
      setLoading(false);
    });
  }

  const searchAccounts = (keyword: string) => {
    setSearching(true);

    AccountApi.search(keyword, role)
    .then((apiResponse: any) => {
      if (apiResponse.data.length > 0) {
        setAccounts(apiResponse.data);
      } else {
        setAccounts([]);
      }
      setSearching(false);
    });
  }

  const updateSearch = (text: string) => {
    setKeyword(text)
    if (text.length > 2) {
      searchAccounts(text)
    }
  }

  const clearSearch = () => {
    setKeyword(undefined);
    loadAccounts("Admin", 0);
  }

  const drawBeerCard = (({ item }: { item: any }) => {
    return (
    <View style={styles.card}>
      <View style={styles.cardHeaderContainer}>
        {item.beer.photo.length > 0 && <Image source={{ uri: baseURL + "/beer/photo/file/" + item.beer.id + "/" + item.beer.photo }} resizeMode="contain" style={{ height: 100, width: 50, margin: 10 }} />}
        {item.beer.photo.length == 0 && <Image source={require('../../assets/images/can.png')} resizeMode="contain" style={{ height: 100, width: 50, margin: 10 }} />}
        <View style={{ marginTop: 5 }}>
          <Text style={styles.cardHeader}>{item.beer.title}</Text>
          <Text style={styles.cardSubheader}>{item.beer.organizationTitle}</Text>
          <Text style={styles.cardRating}>{item.rating}</Text>
        </View>
      </View>
    </View>)
  })

  const drawRecommendationsCard = (({ item }: { item: any }) => {
    return (
    <View style={styles.card}>
      <View style={styles.cardHeaderContainer}>
        {item.photo.length > 0 && <Image source={{ uri: baseURL + "/beer/photo/file/" + item.id + "/" + item.photo }} resizeMode="contain" style={{ height: 100, width: 50, margin: 10 }} />}
        {item.photo.length == 0 && <Image source={require('../../assets/images/can.png')} resizeMode="contain" style={{ height: 100, width: 50, margin: 10 }} />}
        <View style={{ marginTop: 5 }}>
          <Text style={styles.cardHeader}>{item.title}</Text>
          <Text style={styles.cardSubheader}>{item.organizationTitle}</Text>
          <Text style={styles.cardRating}>{item.percentage.toFixed(0)}%</Text>
        </View>
      </View>
    </View>)
  })

  const drawCard = (({item} : {item: Account}) => {
    return (
      <TouchableOpacity onPress={() => editAccount(item)}>
        <View style={styles.card}>
          <View>
            <Text style={styles.cardHeader}>{item.firstName} {item.lastName}</Text>
            <Text style={styles.cardSubHeader}>{item.userName}</Text>
          </View>
        </View>
      </TouchableOpacity>)
  })

  useEffect(() => {
    loadOrganizations();
  }, []);

  useEffect(() => {
    loadAccounts(role, 1);
  }, [page]);

  useFocusEffect (
    React.useCallback(() => {      
      if (isFocused) {
        loadAccounts("Admin", 0);
      }
    }, [isFocused])
  );

  return (<View>    
            {showModal && !saving && <Modal
              style={styles.modalView}
              animationType="slide"
              transparent={true}
              visible={showModal}
              onRequestClose={() => {
                setShowModal(!showModal);
              }}
            >
              <View>
                <Text style={styles.modalText}>Account Editor</Text>    
                <Text style={styles.inputLabel}>Email</Text>
                <View style={styles.inputGroup}>
                  <TextInput
                    style={styles.input}
                    placeholder="Enter account email address"
                    placeholderTextColor="#003f5c"
                    value={email}
                    onChangeText={(email) => {
                      setEmail(email);
                    }}
                  />
                </View>    
                <Text style={styles.inputLabel}>First Name</Text>
                <View style={styles.inputGroup}>
                  <TextInput
                    style={styles.input}
                    placeholder="Enter first name"
                    placeholderTextColor="#003f5c"
                    value={firstName}
                    onChangeText={(firstName) => {
                      setFirstName(firstName);
                    }}
                  />
                </View>    
                <Text style={styles.inputLabel}>Last Name</Text>
                <View style={styles.inputGroup}>
                  <TextInput
                    style={styles.input}
                    placeholder="Enter last name"
                    placeholderTextColor="#003f5c"
                    value={lastName}
                    onChangeText={(lastName) => {
                      setLastName(lastName);
                    }}
                  />
                </View>  
                <Text style={styles.inputLabel}>Organization</Text>
                <View style={styles.inputGroup}>
                  <Picker
                    style={styles.input}
                    selectedValue={organizationId}
                    onValueChange={(itemValue, itemIndex) => setOrganizationId(itemValue)}>
                    {
                      organizations.map( (v: any) => {
                        return <Picker.Item key={v.value} label={v.label} value={v.value} />
                      })
                    }
                  </Picker>
                </View>  

                {newAccount && <View>
                  <Text style={styles.inputLabel}>Password</Text>
                  <Text style={styles.inputSubLabel}>Password requirements: at least 8 characters, one capital letter, a number and special character.</Text>
                  <View style={styles.inputGroup}>
                    <TextInput
                      style={styles.input}
                      placeholder="Enter a password"
                      placeholderTextColor="#003f5c"
                      textContentType="password"
                      secureTextEntry={true}
                      value={password}
                      onChangeText={(password) => {
                        setPassword(password);
                      }}
                    />
                  </View> 
                  <Text style={styles.inputLabel}>Confirm Password</Text>
                  <View style={styles.inputGroup}>
                    <TextInput
                      style={styles.input}
                      placeholder="Confirm password"
                      placeholderTextColor="#003f5c"
                      textContentType="password"
                      secureTextEntry={true}
                      value={confirmPassword}
                      onChangeText={(confirmPassword) => {
                        setConfirmPassword(confirmPassword);
                      }}
                    />
                  </View>  
                </View>}
                <Button style={styles.entryButton} onPress={() => saveAccount()}
                  text="Save">
                </Button>
                <Button style={styles.entryButton} onPress={() => setShowModal(!showModal)}
                  text="Cancel">
                </Button>
              </View>
            </Modal>}   
            {showUserModal && <Modal
              style={styles.modalView}
              animationType="slide"
              transparent={true}
              visible={showUserModal}
              onRequestClose={() => {
                setShowUserModal(!showUserModal);
              }}
            >
              <View>
                <Text style={styles.modalText}>{firstName}  {lastName}</Text>  
                <Text style={styles.modalSubText}>{personality}</Text>      
                <Button style={styles.entryButton} onPress={() => setShowUserModal(false)}
                  text="Close">
                </Button>   
                {loadingUser &&
                  <View style={{marginTop: 40}}>
                    <ActivityIndicator size="large" color="#3F51B5" />
                    <Text style={styles.loadingText}>Predicting recommendations, please wait...</Text>
                  </View>
                } 
                {!loadingUser && <View style={{flexDirection: 'row', flex: 1}}>
                  <Button
                    style={styles.modalHeaderButton}
                    text="Rated Beer"
                    onPress={() => setUserView(1)} />
                  <Button
                    style={styles.modalHeaderButton}
                    text="Recommendations"
                    onPress={() => setUserView(2)} />
                  <Button
                    style={styles.modalHeaderButton}
                    text="Recommendations (Loplops Only)"
                    onPress={() => setUserView(3)} />
                    </View>}
                {!loadingUser && userView == 1 && <View>                  
                  {beers.length == 0 && !loading && <Text style={styles.noLog}>No beer rated.</Text>}
                  {beers.length > 0 && !loading && <FlatList
                      keyExtractor={(item: any, index) => item.id.toString()}
                      data={beers}
                      renderItem={drawBeerCard}
                    />}
                </View>}
                {!loadingUser && userView == 2 && <View>                  
                  {recommendations.length == 0 && !loading && <Text style={styles.noLog}>No recommendations.</Text>}
                  {recommendations.length > 0 && !loading && <FlatList
                      keyExtractor={(item: any, index) => item.id.toString()}
                      data={recommendations}
                      renderItem={drawRecommendationsCard}
                    />}
                </View>}
                {!loadingUser && userView == 3 && <View>                  
                  {recommendations.length == 0 && !loading && <Text style={styles.noLog}>No recommendations.</Text>}
                  {recommendations.length > 0 && !loading && <FlatList
                      keyExtractor={(item: any, index) => item.id.toString()}
                      data={loplopsRecommendations}
                      renderItem={drawRecommendationsCard}
                    />}
                </View>}
                <Button style={styles.entryButton} onPress={() => setShowUserModal(false)}
                  text="Close">
                </Button>
              </View>
            </Modal>}       
            {loading &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.loadingText}>Loading...</Text>
              </View>
            }   
            {saving &&
              <View style={{marginTop: 40}}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.loadingText}>Saving...</Text>
              </View>
            }
            {!loading && !showModal && !showUserModal &&        
              <View style={styles.sectionContainer}>
                <View style={styles.sectionHeaderView}>
                  <Text style={styles.sectionHeader}>ACCOUNTS - {role}</Text>
                  <Button
                    style={styles.sectionHeaderButton}
                    text="Add"
                    onPress={addAccount} />
                </View>

                {!organizationId && <SafeSearchBar
                  platform={"default"}
                  searchIcon={false}
                  onChangeText={updateSearch}
                  onCancel={clearSearch}
                  value={keyword}
                  placeholder="Search"
                  inputContainerStyle={styles.searchInputContainer}
                  inputStyle={styles.searchInput}
                  containerStyle={styles.searchContainer}
                />}

                {searching &&
                  <View style={{marginTop: 40}}>
                    <ActivityIndicator size="large" color="#3F51B5" />
                    <Text style={styles.loadingText}>Searching...</Text>
                  </View>
                }

                <View style={{flexDirection: 'row', alignSelf: 'center'}}>
                  <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => loadAccounts("Admin", 0)}>Admins</Text>
                  <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => loadAccounts("Brewery", 0)}>Breweries</Text>
                  <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => loadAccounts("User", 0)}>Users</Text>
                </View>
                <View>
                  {accounts.length == 0 && !searching && <Text style={styles.noLog}>No accounts in the database.</Text>} 
                  {accounts.length > 0 && !searching && <FlatList
                    keyExtractor={(item: any, index) => item.id}
                    data={accounts}
                    renderItem={drawCard}
                    />}
                  {!keyword && <Button
                    style={styles.entryButton}
                    text="Load More"
                    onPress={() => setPage(page + 1)} />}
                </View>
              </View>}
          </View>
  )
};

const styles = StyleSheet.create({ 
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white,
  },
  modalText: {
    textAlign: 'center',
    fontSize: 20,
    margin: 20,
  },
  modalSubText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    margin: 20,
  },
  entryButton: {
    margin: 10,
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
    fontWeight: 'bold'
  },

  
  inputSubLabel: {
    fontSize: 14,
    marginTop: 10,
    marginLeft: 25,
  },


  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  }, 

  cardHeaderContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardRating: {
    fontSize: 26,
    fontWeight: 'bold',
    marginTop: 10
  },

  cardSubheader: {
    fontSize: 18,
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardInfoContainer: {
  },

  cardDetail: {
    fontSize: 14,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  modalHeaderButton : {
    margin: 10,
    flex: 1
  },
  sectionHeaderButton : {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  progressBar: {
    height: 20,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5
  },
  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  searchInputContainer: {
    backgroundColor: Colors.white,
  }
});
export default AccountList