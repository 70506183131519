import axiosInstance from "../../interceptor";

const AccountApi = {
  add: (email: string, firstName: string, lastName: string, organizationId: number, pass: string) => axiosInstance.post('/auth/signup', {password: pass, firstName: firstName, lastName: lastName, userName: email, role: "Brewery", organizationId: organizationId}),
  update: (id: string, email: string, firstName: string, lastName: string, organizationId: number) => axiosInstance.post('/auth/update', {id: id, firstName: firstName, lastName: lastName, userName: email, organizationId: organizationId}),
  list: (role: string, page: number) => axiosInstance.get('/auth/list/' + role + '/' + page),
  search: (keyword: string, role: string) => axiosInstance.get('/auth/search/' + keyword + '/' + role),
  rec: () => axiosInstance.get('/account/recommend/train'),
  loadSurvey: (id: string) => axiosInstance.get('/account/' + id + '/survey'),
};

export default AccountApi;
