import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { Image } from 'react-native';
import { useSelector } from 'react-redux';
import BackButton from '../components/menu/BackButton';
import HamburgerMenu from '../components/menu/Hamburger';
import AccountsScreen from '../screens/Accounts';
import OrganizationScreen from '../screens/Organization';
import DashboardScreen from '../screens/Dashboard';
import LoginScreen from '../screens/Login';
import { RootState } from '../services/store';
import { RootStackParamList } from './types';
import BeersScreen from '../screens/Beers';
import StylesScreen from '../screens/Styles';
import IngredientsScreen from '../screens/Ingredients';
import AdjunctsScreen from '../screens/Adjuncts';
import OthersScreen from '../screens/Others';
import { baseURL } from '../services/global';
import BeerScreen from '../screens/Beer';
import BeerMergeScreen from '../components/beer/BeerMerge';

export default function Navigation() {
  return (
    <NavigationContainer>
      <RootNavigator />
    </NavigationContainer>
  );
}

const LoginStack = createNativeStackNavigator<RootStackParamList>();
const MainStack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const authState = useSelector((state: RootState) => state.auth);

  const isBrewery = authState.role == "Brewery";

  if (!authState.authenticated) {
    return (
      <LoginStack.Navigator>
        <LoginStack.Screen
          name="Login"
          component={LoginScreen}
          options={{ headerShown: false }}
        />
      </LoginStack.Navigator>
    );
  } else {
    return (<MainStack.Navigator>
      <MainStack.Screen name="Dashboard"
        component={DashboardScreen}
        options={{
          headerRight: () => <HamburgerMenu />,
          headerLeft: () => <HeaderImage isBrewery={true} organization={authState.organizationId}/>,
          headerTitleAlign: 'center',
          title: authState.role == "Brewery" ? authState.organizationTitle : "Home",
        }} />
      <MainStack.Screen name="Accounts"
        component={AccountsScreen}
        options={{
          headerLeft: () => <BackButton />,
          headerTitleAlign: 'center',
          title: "Accounts"
        }} />
      <MainStack.Screen name="Beer"
        component={BeerScreen}
        options={{
          headerLeft: () => <BackButton />,
          headerTitleAlign: 'center',
          title: "Beer"
        }} />
      <MainStack.Screen name="Beers"
        component={BeersScreen}
        options={{
          headerLeft: () => <BackButton />,
          headerTitleAlign: 'center',
          title: "Beers"
        }} />
      <MainStack.Screen name="Organization"
        component={OrganizationScreen}
        options={{
          headerLeft: () => <BackButton />,
          headerTitleAlign: 'center',
          title: "Organization"
        }} />
      <MainStack.Screen name="Styles"
        component={StylesScreen}
        options={{
          headerLeft: () => <BackButton />,
          headerTitleAlign: 'center',
          title: "Types"
        }} />
      <MainStack.Screen name="Ingredients"
        component={IngredientsScreen}
        options={{
          headerLeft: () => <BackButton />,
          headerTitleAlign: 'center',
          title: "Ingedients"
        }} />

      <MainStack.Screen name="Adjuncts"
        component={AdjunctsScreen}
        options={{
          headerLeft: () => <BackButton />,
          headerTitleAlign: 'center',
          title: "Adjuncts"
        }} />



      <MainStack.Screen name="Others"
        component={OthersScreen}
        options={{
          headerLeft: () => <BackButton />,
          headerTitleAlign: 'center',
          title: "Styles"
        }} />


    </MainStack.Navigator>);
  }
}

/*
<Drawer.Screen name="Edit Profile" component={ProfileEditorScene}
              options={{
                title: 'Profile Editor',
                headerStyle: {
                  backgroundColor: colors.primary,
                },
                headerTintColor: '#fff',
                headerTitleStyle: {
                  fontWeight: 'bold',
                }}} />
        <Drawer.Screen name="Edit Preferences" component={PreferencesScene}
              options={{
                title: 'Preferences',
                headerStyle: {
                  backgroundColor: colors.primary,
                },
                headerTintColor: '#fff',
                headerTitleStyle: {
                  fontWeight: 'bold',
                }}} />*/

function HeaderImage( props: any ) {
  let imageSource = null;
  
  if(props.isBrewery){
    imageSource = {uri: baseURL + "/organization/photo/file/" + props.organization }
  }
  else {
    imageSource = require('../assets/images/logo.png');
  }

  
  return <Image style={{ width: 100, height: 20, marginLeft: 10, resizeMode: 'contain' }} source={imageSource} />;
}