import { useNavigation } from '@react-navigation/core';
import React from 'react'

import {
  View,
  StyleSheet,
  Text,
  Image,
  Alert,
} from 'react-native';
import { useSelector } from 'react-redux';
import BeerExcludeList from './BeerExcludeList';
import Colors from '../../constants/Colors';
import BeerApi from '../../services/modules/beer/api';
import { RootState } from '../../services/store';
import { Beer } from '../../services/types';
import { baseURL } from '../../services/global';

interface Props {
  beer: Beer,
  onMerged: () => void
}

const BeerMerge: React.FC<Props> = ({ 
  beer,
  onMerged
}) => {   
  const onSelected = (selectedBeer: Beer) => {
    BeerApi.merge(beer.id, selectedBeer.id)
    .then(() => {
      if (confirm("Are you sure you want to merge these two beers?")) {
        onMerged()
      }
    })
  }
  
  return (<View style={{flexDirection:'row'}}>
            <View style={styles.mainContainer}>    
              <Text style={styles.modalText}>Beer Merge</Text> 
              <View style={styles.card}>
                <View style={styles.cardHeaderContainer}>
                  {beer.photo.length > 0 && <Image source={{ uri: baseURL + "/beer/photo/file/" + beer.id + "/" + beer.photo }} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
                  {beer.photo.length == 0 && <Image source={require('../../assets/images/can.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
                  <View style={{ marginTop: 5 }}>
                    <Text style={styles.cardHeader}>{beer.title}</Text>
                    <Text style={styles.cardSubheader}>{beer.organizationTitle}</Text>
                  </View>
                </View>
              </View>
              <View style={{padding: 15}}>
                <Text style={{fontWeight: 'bold', fontSize: 18}}>Search and select the beer you would like to merge this beer into.</Text>  
                <Text>What will happen:</Text>   
                <Text> - The beer details and photo will be taken from the beer you select and this beer will be deleted</Text>
                <Text> - Any locations added for this beer will be merged into the beer you select</Text>
                <Text> - Any user ratings for this beer will be merged into the beer you select</Text>
                <Text> - Any recommendations of this beer will be changed to the beer you select</Text>
              </View>
              <BeerExcludeList excludeId={beer.id} onSelected={onSelected} />
            </View>   
          </View>)
};

const styles = StyleSheet.create({ 
  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },

  mainContainer: {
    backgroundColor: Colors.white, 
    padding: "1%",
    margin: "1%",
    width: "98%"
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubheader: {
    fontSize: 18,
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },
});

export default BeerMerge