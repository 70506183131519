import { useNavigation } from '@react-navigation/core';
import Checkbox from 'expo-checkbox';
import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  Text,
} from 'react-native';
import { useSelector } from 'react-redux';
import BeerList from '../components/beer/BeerList';
import PendingBeer from '../components/beer/PendingBeer';
import OrganizationList from '../components/organization/OrganizationList';

import Button from '../components/ui/Button';
import Colors from '../constants/Colors';
import AppApi from '../services/modules/app/api';
import { RootState } from '../services/store';

interface Props {
}

const DashboardScreen: React.FC<Props> = ({ 
}) => {   
  const navigation = useNavigation();
  const authState = useSelector((state: RootState) => state.auth);

  const [showMenu, setShowMenu] = React.useState(false);

  const loadSettings = () => {
    AppApi.loadShowMenu()
    .then((apiResponse: any) => {
      if (apiResponse.data[0].value == "1") {
        setShowMenu(true);
      } else {
        setShowMenu(false);
      }
    })
  }

  const saveSettings = (showMenuValue: boolean) => {
    setShowMenu(showMenuValue)

    let storeValue = "0";

    if (showMenuValue) {
      storeValue = "1";      
    }

    AppApi.saveShowMenu(storeValue)
    .then((apiResponse: any) => {
    })
  }

  useEffect(() => {
    loadSettings()
  }, [])

  if (authState.role == "Brewery") {
    return (<View> 
              <Text style={styles.header}>Welcome {authState.firstName} {}</Text>
              <View style={styles.organizationLoggedInContainer}>
                <BeerList organizationId={authState.organizationId}/>
              </View>   
            </View>)
  } else {
    return (<View> 
              <Text style={styles.header}>Welcome {authState.firstName}</Text>
              <View style={styles.checkboxContainer}>
                  <Checkbox
                    value={showMenu}
                    onValueChange={saveSettings}
                    style={styles.checkbox}
                  />
                  <Text style={styles.label}>Show festival menu in app</Text>
                </View>
              <View style={{flexDirection:'row'}}> 
                <View style={styles.dashboardContainer}>
                  <PendingBeer />
                  <View style={styles.organizationContainer}>    
                    <OrganizationList typeId={1}/>
                  </View>
                </View>   
                <View style={styles.sideNavigation}> 
                  <Button style={styles.button} onPress={() => { navigation.navigate("Accounts")}} text="Accounts" />
                  <Button style={styles.button} onPress={() => { navigation.navigate("Beers")}} text="Beer" />
                  <Button style={styles.button} onPress={() => { navigation.navigate("Styles")}} text="Types" />
                  <Button style={styles.button} onPress={() => { navigation.navigate("Ingredients")}} text="Ingredients" />
                  <Button style={styles.button} onPress={() => { navigation.navigate("Adjuncts")}} text="Adjuncts" />
                  <Button style={styles.button} onPress={() => { navigation.navigate("Others")}} text="Styles" />
                </View>
              </View>
            </View>)
  }
};

const styles = StyleSheet.create({ 
  button: {
    marginTop: 5,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },

  header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 10,
    marginLeft: 10,
  },

  dashboardContainer: {  
    width: "69%",
    marginLeft: "1%",
    marginVertical: "1%",
  },

  organizationContainer: {   
    backgroundColor: Colors.white, 
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,  
    padding: "1%",
    width: "98%"
  },

  organizationLoggedInContainer: {   
    backgroundColor: Colors.white, 
    padding: "1%",
    margin: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "98%"
  },

  sideNavigation: {
    backgroundColor: Colors.white, 
    padding: "1%",
    marginVertical: "1%",
    marginLeft: 0,
    marginRight: "1%",
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    width: "29%"
  },
  checkboxContainer: {
    flexDirection: "row",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.borderGrey,
    borderWidth: 1,
    borderRadius: 10,
    padding: 10
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
  },
});

export default DashboardScreen