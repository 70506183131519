import React, { useEffect } from 'react'

import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Modal,
  Image,
} from 'react-native';

import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { Beer } from '../../services/types';
import Button from '../ui/Button';
import Colors from '../../constants/Colors';
import BeerApi from '../../services/modules/beer/api';
import OrganizationApi from '../../services/modules/organization/api';
import BeerEditor from './BeerEditor';
import { createBeer } from '../../services/helpers';
import { baseURL } from '../../services/global';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';

interface Props {
  excludeId?: number
  onSelected?: (beer: Beer) => void
}

const BeerExcludeList: React.FC<Props> = ({
  excludeId,
  onSelected
}) => {
  const isFocused = useIsFocused();
  const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;

  const [beers, setBeers] = React.useState([]);
  const [beer, setBeer] = React.useState<Beer>(createBeer());
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);

  const beerPressed = (item: Beer) => {
    if (onSelected) {
      onSelected(item)
    }
  }

  const startsWith = (letter: string) => {  
    setLoading(true);
    BeerApi.startsWithSearch(letter)
    .then((apiResponse: any) => {
      setBeers(apiResponse.data);
      setLoading(false);
    });
  }

  const loadBeers = (searchKeyword: string) => {
    setLoading(true);

    if (excludeId) {
      BeerApi.searchExclude(searchKeyword, excludeId)
      .then((apiResponse: any) => {
        setBeers(apiResponse.data);
        setLoading(false);
      });
    } else {
      BeerApi.search(searchKeyword)
      .then((apiResponse: any) => {
        setBeers(apiResponse.data);
        setLoading(false);
      });
    }    
  }

  const drawCard = (({ item }: { item: Beer }) => {
    return (
      <TouchableOpacity onPress={() => beerPressed(item)}>
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
            {item.photo.length > 0 && <Image source={{ uri: baseURL + "/beer/photo/file/" + item.id + "/" + item.photo }} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
            {item.photo.length == 0 && <Image source={require('../../assets/images/can.png')} resizeMode="contain" style={{ height: 50, width: 50, margin: 10 }} />}
            <View style={{ marginTop: 5 }}>
              <Text style={styles.cardHeader}>{item.title}</Text>
              <Text style={styles.cardSubheader}>{item.organizationTitle}</Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>)
  })

  const updateSearch = (text: string) => {
    setKeyword(text)
    if (text.length > 2) {
      loadBeers(text)
    }

    if (text.length == 0) {
      setBeers([])
    }
  }

  const clearSearch = () => {
    setKeyword(undefined);
    setBeers([])
  }

  return (<View>
            <SafeSearchBar
              platform={"default"}
              searchIcon={false}
              onChangeText={updateSearch}
              onCancel={clearSearch}
              value={keyword}
              placeholder="Search"
              inputContainerStyle={styles.searchInputContainer}
              inputStyle={styles.searchInput}
              containerStyle={styles.searchContainer}
            />

            <View style={{flexDirection: 'row', alignSelf: 'center'}}>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("A")}>A</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("B")}>B</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("C")}>C</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("D")}>D</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("E")}>E</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("F")}>F</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("G")}>G</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("H")}>H</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("I")}>I</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("J")}>J</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("K")}>K</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("L")}>L</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("M")}>M</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("N")}>N</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("O")}>O</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("P")}>P</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("Q")}>Q</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("R")}>R</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("S")}>S</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("T")}>T</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("U")}>U</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("V")}>V</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("W")}>W</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("X")}>X</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("Y")}>Y</Text>
              <Text style={{textDecorationLine: 'underline', marginRight: 8}} onPress={() => startsWith("Z")}>Z</Text>
            </View>

            {loading &&
              <View style={{ marginTop: 40 }}>
                <ActivityIndicator size="large" color="#3F51B5" />
                <Text style={styles.loadingText}>Loading...</Text>
              </View>
            }

            {beers.length == 0 && !loading && <Text style={styles.noLog}>No beer found.</Text>}
            {beers.length > 0 && !loading && <View>
              <FlatList
                keyExtractor={(item: any, index) => item.id.toString()}
                data={beers}
                renderItem={drawCard}
              />
          </View>}
      </View>)
};

const styles = StyleSheet.create({
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    margin: 10,
  },

  inputLabel: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
    textAlign: 'center'
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  selectBox: {
    padding: 5,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  cardHeaderContainer: {
    flexDirection: 'row'
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubheader: {
    fontSize: 18,
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardInfoContainer: {
  },

  cardDetail: {
    fontSize: 14,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderButton: {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  progressBar: {
    height: 20,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5
  },
  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  searchInputContainer: {
    backgroundColor: Colors.white,
  }
});
export default BeerExcludeList