import React from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Modal,
  Alert,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList, TextInput } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';
import { Account, Organization } from '../../services/types';
import { AxiosResponse } from 'axios';
import Button from '../ui/Button';
import Animated from 'react-native-reanimated';
import Colors from '../../constants/Colors';
import { whileStatement } from '@babel/types';
import AccountApi from '../../services/modules/account/api';
import { Picker } from '@react-native-picker/picker';
import OrganizationApi from '../../services/modules/organization/api';
import BeerApi from '../../services/modules/beer/api';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';
import { SearchBar } from 'react-native-elements';
import chroma from 'chroma-js';
import { FontAwesome } from '@expo/vector-icons';
import { components } from 'react-select';
import Select from 'react-select';

interface Props {
}

const IngredientList: React.FC<Props> = ({
}) => {
  const DropdownIndicator = (props: any) => {
    const { selectProps } = props;
    let arrow;
    if (selectProps.menuIsOpen) {
      arrow = <FontAwesome name="filter" color="#000000" size={30} />
    } else {
      arrow = <FontAwesome name="filter" color="#000000" size={30} />
    }
    return <components.DropdownIndicator {...props}>{arrow}</components.DropdownIndicator>;
  }
  const types = [
    { value: 0, label: "Please Select" },
    { value: 1, label: "Hops", color: "red" },
    { value: 2, label: "Yeast", color: "green"},
    { value: 3, label: "Malt", color: "blue"},
    { value: 4, label: "Water Source", color: "yellow" },
  ]
  const isFocused = useIsFocused()

  const [filterTypes, setFilterTypes] = React.useState<any>(types.slice(1));

  const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;
  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);
  const [allIngredients, setAllIngredients] = React.useState([]);
  const [ingredients, setIngredients] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [newIngredient, setNewIngredient] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [typeId, setTypeId] = React.useState(0);
  const [title, setTitle] = React.useState("");

  const addIngredient = () => {
    setNewIngredient(true);
    setTitle("");
    setShowModal(true)
  };

  const editIngredient = (ingredient: any) => {
    setNewIngredient(false);
    setId(ingredient.id);
    setTitle(ingredient.title);
    setTypeId(ingredient.typeId)
    setShowModal(true)
  };

  const saveStyle = () => {
    if (title.trim().length == 0 || typeId == 0) {
      alert("Please fill in all fields.");
      return;
    }

    if (id == 0) {
      setShowModal(false);
      setSaving(true);
      BeerApi.saveIngredient(0, title, typeId)
        .then((apiResponse: any) => {
          setSaving(false);
          init();
        })
        .catch((error) => {
          setSaving(false);
        })
    } else {
      setShowModal(false);
      setSaving(true);
      BeerApi.saveIngredient(id, title, typeId)
        .then((apiResponse: any) => {
          setSaving(false);
          init();
        })
        .catch((error) => {
          console.log(error)
          setSaving(false);
        })
    }
  }



  const removeIngredient = () => {
    if (confirm("Are you sure you want to delete this ingredient?")) {
      if (id > 0) {
        setShowModal(false);
        setSaving(true);
        BeerApi.removeIngredient(id)
          .then((apiResponse: any) => {
            setSaving(false);
            init();
          })
          .catch((error) => {
            console.log(error)
            setSaving(false);
          })
      }
    }
  }

  const init = () => {
    setLoading(true);

    BeerApi.listIngredients(0)
      .then((apiResponse: any) => {
        if (apiResponse.data.length > 0) {
          setIngredients(apiResponse.data);
          setAllIngredients(apiResponse.data);
        }
        setLoading(false);
      });
  }

  const customStyles = {
    //https://react-select.com/styles#styles

    menuPortal: (provided: any, state: any) => ({
      ...provided,
      zIndex: 9999,
    }),

    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius: 20,
      borderColor: Colors.borderGrey,
      marginHorizontal: 10,
    }),


    multiValue: (provided: any, { data }) => {
      const color = chroma(data.color);
      return {
        ...provided,
        borderRadius: 15,
        fontSize: 18,
        backgroundColor: color.alpha(0.1).css(),

      };
    },




  }
  

  const drawCard = (({ item }: { item: any }) => {
    if (filterTypes.some(f => f.value == item.typeId)) {
    return (
      <TouchableOpacity onPress={() => editIngredient(item)}>
        <View style={{
           backgroundColor: chroma(types[item.typeId].color).alpha(0.1).css(),
           borderColor: Colors.primary,
           borderRadius: 5,
           borderWidth: 1,
           padding: 10,
           margin: 10,
        }}>
          <View style={styles.cardHeaderContainer}>
            <Text style={styles.cardHeader}>{item.firstName} {item.title}</Text>
          </View>
        </View>
      </TouchableOpacity>)
    }
    else { return null; }
  })




  const updateSearch = (text: string) => {
    setKeyword(text)
    setIngredients(allIngredients.filter((item: any) => { return item.title.toLowerCase().indexOf(text.toLowerCase()) >= 0 }));
  }


  const clearSearch = () => {
    setKeyword(undefined);
    setIngredients(allIngredients)
  }

  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        init();
      }
    }, [isFocused])
  );

  return (<View>
    {showModal && !saving && <Modal
      style={styles.modalView}
      animationType="slide"
      transparent={true}
      visible={showModal}
      onRequestClose={() => {
        setShowModal(!showModal);
      }}
    >
      <View>
        <Text style={styles.modalText}>Ingredient Editor</Text>
        <Text style={styles.inputLabel}>Title</Text>
        <View style={styles.inputGroup}>
          <TextInput
            style={styles.input}
            placeholder="Enter ingredient title"
            placeholderTextColor="#003f5c"
            value={title}
            onChangeText={(title) => {
              setTitle(title);
            }}
          />
        </View>

        <Text style={styles.inputLabel}>Type</Text>
        <View style={styles.inputGroup}>
          <Picker
            style={styles.input}
            selectedValue={typeId}
            onValueChange={(itemValue, itemIndex) => setTypeId(itemValue)}>
            {
              types.map((v: any) => {
                return <Picker.Item key={v.value} label={v.label} value={v.value} />
              })
            }
          </Picker>
        </View>


        <Button style={styles.entryButton} onPress={() => saveStyle()}
          text="Save">
        </Button>
        <Button style={styles.entryButton} onPress={() => { setFilterTypes(types.slice(1)); setShowModal(!showModal)}}
          text="Cancel">
        </Button>

        {!newIngredient && <Button style={[styles.entryButton, { marginTop: 40 }]} onPress={() => removeIngredient()}
          text="Remove">
        </Button>}
      </View>
    </Modal>}
    {loading &&
      <View style={{ marginTop: 40 }}>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.loadingText}>Loading...</Text>
      </View>
    }
    {saving &&
      <View style={{ marginTop: 40 }}>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.loadingText}>Saving...</Text>
      </View>
    }
    {!showModal &&
      <View style={styles.sectionContainer}>
        <View style={styles.sectionHeaderView}>
          <Text style={styles.sectionHeader}>INGREDIENTS</Text>
          <Button
            style={styles.sectionHeaderButton}
            text="Add"
            onPress={addIngredient} />
        </View>

        <SafeSearchBar
          platform={"default"}
          searchIcon={false}
          onChangeText={updateSearch}
          onClear={clearSearch}
          onCancel={clearSearch}
          value={keyword}
          placeholder="Search"
          inputContainerStyle={styles.searchInputContainer}
          inputStyle={styles.searchInput}
          containerStyle={styles.searchContainer}
        />

   <View style={{paddingHorizontal: 15}}>     
<Select
          isMulti
          menuPortalTarget={document.body}
          closeMenuOnScroll={true}
          isSearchable={false}
          menuPlacement="top"
          styles={customStyles}
          components={{ DropdownIndicator }}
          name="types"
          defaultValue={types.slice(1)}
          options={types.slice(1)}
          onChange={setFilterTypes}
          closeMenuOnSelect={false} />
</View>

        <View>
          {ingredients.length == 0 && <Text style={styles.noLog}>No ingredients in the database.</Text>}
          {ingredients.length > 0 && <FlatList
            keyExtractor={(item: any, index) => item.id.toString()}
            data={ingredients}
            renderItem={drawCard}
          />}
        </View>
      </View>}
  </View>
  )
};

const styles = StyleSheet.create({
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white,
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    margin: 10,
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
    fontWeight: 'bold'
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardInfoContainer: {
  },

  cardDetail: {
    fontSize: 14,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderButton: {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  progressBar: {
    height: 20,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5
  },
  searchContainer: {
    backgroundColor: Colors.white,
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  searchInputContainer: {
    backgroundColor: Colors.white,
  }
});
export default IngredientList