import AsyncStorage from "@react-native-async-storage/async-storage";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { baseURL } from "./global";
import { REFRESH_TOKEN } from "./modules/auth/types";
import store from "./store";

const axiosInstance = axios.create({
  baseURL: baseURL,
});

var refreshed = false;

const requestHandler = async (request: AxiosRequestConfig) => {   
  if (request.headers) {
    if (!request.url?.includes("signup") && !request.url?.includes("login") && !refreshed) {
      request.headers.Authorization = 'bearer ' + store.getState().auth.token;
    }

    if (request.url?.includes("beer/photo")) {
      request.headers["Content-Type"] = "multipart/form-data";
    }

    if (refreshed) {
      refreshed = false;
    }

    request.headers["Content-Security-Policy"] = "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'";
    request.headers["X-Content-Type-Options"] = "nosniff";
    request.headers["X-XSS-Protection"] = "1";
    request.headers["Strict-Transport-Security"] = "max-age=31536000";
  }

  return request;
};

const errorHandler = async (error: AxiosError) => {
  if (error.response?.status === 401) {
    if (error.response.headers.tokenexpired) {
      return refreshToken(error.config);
    }
  }

  if (Number(error.response?.status) >= 400) {
    await Promise.reject(error.response);
  }
};

const successHandler = (response: AxiosResponse) => response;

axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => successHandler(response), 
  (error) => errorHandler(error)
);

const refreshToken = async (requestConfig: AxiosRequestConfig) => { 
  return axiosInstance.post('/auth/refresh', {token: store.getState().auth.token, refreshToken: store.getState().auth.refreshToken})
  .then(async (apiResponse: any) => { 
    return storeRefreshedToken(apiResponse.data.token, apiResponse.data.refreshToken)
    .then(() => {
      refreshed = true;

      if (requestConfig.headers) {
        requestConfig.headers.Authorization = 'bearer ' + apiResponse.data.token;
      }
      
      return axiosInstance(requestConfig);
    })
    .catch((error) => {
      Promise.reject(error.response);
    })
  });
};

const storeRefreshedToken = async (token: string, refreshToken: string) => {
  try {
    //TODO: better local storage
    await AsyncStorage.setItem(
      'user.token',
      token
    );
    await AsyncStorage.setItem(
      'user.refreshToken',
      refreshToken
    );      
    
    let temp = store.getState().auth;

    if (temp != null) {
      store.dispatch({type: REFRESH_TOKEN, userData: {id: temp.id, firstName: temp.firstName, lastName: temp.lastName, token: token, refreshToken: refreshToken, email: '', password: '', photo: ''}});
    }

    return true;
  } catch(error) {
    return false;
  }
};

export default axiosInstance;