export const createBeer = () => {
  return {
    id: 0,
    title: '',
    adjuncts: [],
    ingredients: [],
    styles: [],
    others: [],
    abv: 0,
    ibu: 0,
    photo: '',
    originalPhoto: '',
    organizationId: 0,
    organizationTitle: '',
    core: false,
    seasonal: false,
    newRelease: false,
    regularNameScore: 0,
    punnyNameScore: 0,
    wildNameScore: 0,
    reviewed: false,
    releaseDate: undefined
  }
}

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date: Date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      // padTo2Digits(date.getSeconds()),  
    ].join(':')
  );
}