import React from 'react'

import {
  useColorScheme,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Modal,
  Alert,
} from 'react-native';

import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { Text } from 'react-native';
import { FlatList, TextInput } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';
import { Account, Organization } from '../../services/types';
import { AxiosResponse } from 'axios';
import Button from '../ui/Button';
import Animated from 'react-native-reanimated';
import Colors from '../../constants/Colors';
import { whileStatement } from '@babel/types';
import AccountApi from '../../services/modules/account/api';
import { Picker } from '@react-native-picker/picker';
import OrganizationApi from '../../services/modules/organization/api';
import BeerApi from '../../services/modules/beer/api';
import { SearchBar } from 'react-native-elements';
import { SearchBarBaseProps } from 'react-native-elements/dist/searchbar/SearchBar';

interface Props {
}

const StyleList: React.FC<Props> = ({
}) => {
  const isFocused = useIsFocused()

  const SafeSearchBar = (SearchBar as unknown) as React.FC<SearchBarBaseProps>;
  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);
  const [allBeerStyles, setAllBeerStyles] = React.useState([]);
  const [beerStyles, setBeerStyles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [newStyle, setNewStyle] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");

  const addStyle = () => {
    setNewStyle(true);
    setTitle("");
    setShowModal(true)
  };

  const editStyle = (style: any) => {
    setNewStyle(false)
    setId(style.id)
    setTitle(style.title)
    setDescription(style.description)
    setShowModal(true)
  };

  const saveStyle = () => {
    if (title.trim().length == 0) {
      alert("Please enter a title.");
      return;
    }

    if (id == 0) {
      setShowModal(false);
      setSaving(true);
      BeerApi.saveStyle(0, title, description)
        .then((apiResponse: any) => {
          setSaving(false);
          init();
        })
        .catch((error) => {
          setSaving(false);
        })
    } else {
      setShowModal(false);
      setSaving(true);
      BeerApi.saveStyle(id, title, description)
        .then((apiResponse: any) => {
          setSaving(false);
          init();
        })
        .catch((error) => {
          console.log(error)
          setSaving(false);
        })
    }
  }

  const removeStyle = () => {
    if (confirm("Are you sure you want to delete this type?")) {
      if (id > 0) {
        setShowModal(false);
        setSaving(true);
        BeerApi.removeStyle(id)
          .then((apiResponse: any) => {
            setSaving(false);
            init();
          })
          .catch((error) => {
            console.log(error)
            setSaving(false);
          })
      }
    }
  }

  const init = () => {
    setLoading(true);

    BeerApi.listStyles()
      .then((apiResponse: any) => {
        if (apiResponse.data.length > 0) {
          setBeerStyles(apiResponse.data);
          setAllBeerStyles(apiResponse.data);
        }
        setLoading(false);
      });
  }

  const drawCard = (({ item }: { item: any }) => {
    return (
      <TouchableOpacity onPress={() => editStyle(item)}>
        <View style={styles.card}>
          <View style={styles.cardHeaderContainer}>
            <Text style={styles.cardHeader}>{item.firstName} {item.title}</Text>
          </View>
        </View>
      </TouchableOpacity>)
  })


  
  const updateSearch = (text: string) => {
    setKeyword(text)
    setBeerStyles(allBeerStyles.filter((item: any) => {return item.title.toLowerCase().indexOf(text.toLowerCase()) >= 0}));
  }


  const clearSearch = () => {
    setKeyword(undefined);
    setBeerStyles(allBeerStyles)
  }

  useFocusEffect(
    React.useCallback(() => {
      if (isFocused) {
        init();
      }
    }, [isFocused])
  );

  return (<View>
    {showModal && !saving && <Modal
      style={styles.modalView}
      animationType="slide"
      transparent={true}
      visible={showModal}
      onRequestClose={() => {
        setShowModal(!showModal);
      }}
    >
      <View>
        <Text style={styles.modalText}>Type Editor</Text>
        <Text style={styles.inputLabel}>Title</Text>
        <View style={styles.inputGroup}>
          <TextInput
            style={styles.input}
            placeholder="Enter type title"
            placeholderTextColor="#003f5c"
            value={title}
            onChangeText={(title) => {
              setTitle(title);
            }}
          />
        </View>
        <Text style={styles.inputLabel}>Description</Text>
        <View style={styles.inputGroup}>
          <TextInput
            style={[styles.input, {height: 250}]}
            placeholder="Enter type description"
            numberOfLines={8}
            multiline={true}
            placeholderTextColor="#003f5c"
            value={description}
            onChangeText={(description) => {
              setDescription(description);
            }}
          />
        </View>

        <Button style={styles.entryButton} onPress={() => saveStyle()}
          text="Save">
        </Button>
        <Button style={styles.entryButton} onPress={() => setShowModal(!showModal)}
          text="Cancel">
        </Button>

        {!newStyle && <Button style={[styles.entryButton, {marginTop: 40}]} onPress={() => removeStyle()}
          text="Remove">
        </Button>}

      </View>
    </Modal>}
    {loading &&
      <View style={{ marginTop: 40 }}>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.loadingText}>Loading...</Text>
      </View>
    }
    {saving &&
      <View style={{ marginTop: 40 }}>
        <ActivityIndicator size="large" color="#3F51B5" />
        <Text style={styles.loadingText}>Saving...</Text>
      </View>
    }
    {!showModal &&
      <View style={styles.sectionContainer}>
        <View style={styles.sectionHeaderView}>
          <Text style={styles.sectionHeader}>TYPES</Text>
          <Button
            style={styles.sectionHeaderButton}
            text="Add"
            onPress={addStyle} />
        </View>

        <SafeSearchBar
          platform={"default"}
          searchIcon={false}
          onChangeText={updateSearch}
          onClear={clearSearch}
          onCancel={clearSearch}
          value={keyword}
          placeholder="Search"
          inputContainerStyle={styles.searchInputContainer}
          inputStyle={styles.searchInput}
          containerStyle={styles.searchContainer}
        />


        <View>
          {beerStyles.length == 0 && <Text style={styles.noLog}>No types in the database.</Text>}
          {beerStyles.length > 0 && <FlatList
            keyExtractor={(item: any, index) => item.id.toString()}
            data={beerStyles}
            renderItem={drawCard}
          />}
        </View>
      </View>}
  </View>
  )
};

const styles = StyleSheet.create({
  modalView: {
    width: "98%",
    margin: "1%",
    backgroundColor: Colors.white,
    borderColor: Colors.white,
  },
  modalText: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 20,
  },
  entryButton: {
    margin: 10,
  },

  inputLabel: {
    fontSize: 16,
    marginTop: 10,
    marginLeft: 15,
    fontWeight: 'bold'
  },

  inputGroup: {
    flexDirection: 'row',
    marginLeft: 5,
  },
  input: {
    height: 50,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    margin: 10,
    flex: 1,
    borderColor: Colors.borderLightGrey,
    borderWidth: 1,
    borderRadius: 20
  },

  noLog: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 20,
  },

  card: {
    borderColor: Colors.primary,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
    margin: 10,
  },

  cardHeaderContainer: {
  },

  cardHeader: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  cardSubHeader: {
    fontSize: 16,
    fontStyle: 'italic'
  },

  cardInfoContainer: {
  },

  cardDetail: {
    fontSize: 14,
  },

  loadingText: {
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  sectionContainer: {
    width: "100%",
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    flex: 1,
    margin: 4,
  },
  sectionHeaderButton: {
    margin: 4,
  },
  sectionHeaderView: {
    flexDirection: 'row',
  },
  progressBar: {
    height: 20,
    flexDirection: "row",
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5
  },
  searchContainer: {
    backgroundColor: Colors.white, 
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent'
  },
  searchInput: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    borderColor: Colors.borderGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10
  },
  searchInputContainer: {
    backgroundColor: Colors.white,
  }
});
export default StyleList